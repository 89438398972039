/* global fbq,_learnq,pintrk */
import React, { useEffect, useState, useContext } from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";

import StoreContext from "../../context/storeContext";

import { decodeId, getFromPrice, mapVariantOptions } from "../../utils";
import CONSTANTS from "../../constants";

// Hooks
import usePrevious from "../../hooks/usePrevious";

const forceTracking =
  process.env.FORCE_TRACKING &&
  process.env.FORCE_TRACKING.toLowerCase() === "true";
const isDev = process.env.DEV && process.env.DEV.toLowerCase() === "true";
const isTracking =
  (process.env.NODE_ENV === `production` && !isDev) || forceTracking;

function BuffyTracking({ fbId, pintId, layout }) {
  const {
    store: {
      currentProduct,
      currentVariant,
      currentQuantity,
      adding,
      oosSuccess,
    },
    updateOosSuccess,
  } = useContext(StoreContext);

  const [fbPixelLoaded, setFBPixelLoaded] = useState();
  const [pintPixelLoaded, setPintPixelLoaded] = useState();

  // FB View Product Page Event
  const prevCurrentVariant = usePrevious(currentVariant);
  useEffect(() => {
    if (
      fbPixelLoaded &&
      currentProduct &&
      currentVariant &&
      (!prevCurrentVariant || currentVariant.id !== prevCurrentVariant.id) &&
      layout === "product"
    ) {
      // ContentView
      // console.log("product pageview", currentVariant);
      fbq("track", "ViewContent", {
        content_name: `${currentProduct.shopifyProduct.title} - ${currentVariant.title}`,
        content_ids: `${decodeId(currentProduct.shopifyProduct.shopifyId)}`,
        content_type: "product_group",
        value: `${currentVariant.price.amount}`,
        currency: CONSTANTS.CURRENCY,
        ...(currentQuantity ? { num_items: currentQuantity } : undefined),
      });
    }
  }, [fbPixelLoaded, currentProduct, currentVariant, currentQuantity]);

  // Pinterest View Product Page Event
  useEffect(() => {
    // const eventTime = Math.floor(Date.now() / 1000);
    if (
      pintPixelLoaded &&
      currentProduct &&
      currentVariant &&
      (!prevCurrentVariant || currentVariant.id !== prevCurrentVariant.id) &&
      layout === "product"
    ) {
      // PageView
      pintrk("track", "pagevisit", {
        value: `${currentVariant.price.amount}`,
        ...(currentQuantity ? { order_quantity: currentQuantity } : undefined),
        currency: CONSTANTS.CURRENCY,
        line_items: [
          {
            product_category: "product_group",
            product_name: `${currentProduct.shopifyProduct.title} - ${currentVariant.title}`,
            product_id: `${decodeId(currentProduct.shopifyProduct.shopifyId)}`,
          },
        ],
      });
    }
  }, [pintPixelLoaded, currentProduct, currentVariant, currentQuantity]);

  // Klaviyo View Product Page Event
  useEffect(() => {
    if (currentProduct && layout === "product") {
      if (isTracking && typeof _learnq === `object`) {
        const item = {
          Name: currentProduct.title,
          ProductID: decodeId(currentProduct.shopifyProduct.shopifyId),
          Tags: currentProduct.shopifyProduct.tags,
          ImageURL: `https:${currentProduct.mainImage}`,
          URL: window.location.href,
          Brand: currentProduct.shopifyProduct.vendor,
          Price: getFromPrice(currentProduct.shopifyProduct.variants.edges),
        };
        _learnq.push(["track", "Viewed Product (react)", item]);
        _learnq.push([
          "trackViewedItem",
          {
            Title: item.Name,
            ItemId: item.ProductID,
            Tags: item.Tags,
            // ImageUrl: item.ImageURL,
            Url: item.URL,
            Metadata: {
              Brand: item.Brand,
              Price: item.Price,
            },
          },
        ]);
      }
    }
  }, [currentProduct]);

  // Add To Cart event
  const prevAdding = usePrevious(adding);
  useEffect(() => {
    if (prevAdding && !adding) {
      if (fbPixelLoaded) {
        fbq("track", "AddToCart", {
          content_name: `${currentProduct.shopifyProduct.title} - ${currentVariant.title}`,
          content_ids: `${decodeId(currentProduct.shopifyProduct.shopifyId)}`,
          content_type: "product_group",
          // content_variant_title: `${currentVariant.title}`,
          value: `${currentVariant.price.amount}`,
          currency: CONSTANTS.CURRENCY,
          num_items: currentQuantity,
        });
      }

      // Pinterest ATC Event
      if (pintPixelLoaded) {
        pintrk("track", "addtocart", {
          value: `${currentVariant.price.amount}`,
          ...(currentQuantity
            ? { order_quantity: currentQuantity }
            : undefined),
          currency: CONSTANTS.CURRENCY,
          line_items: [
            {
              product_category: "product_group",
              product_name: `${currentProduct.shopifyProduct.title} - ${currentVariant.title}`,
              product_id: `${decodeId(
                currentProduct.shopifyProduct.shopifyId,
              )}`,
            },
          ],
        });
      }

      if (isTracking && typeof _learnq === `object`) {
        const item = {
          Name: currentProduct.shopifyProduct.title,
          ProductID: decodeId(currentProduct.shopifyProduct.shopifyId),
          Tags: currentProduct.shopifyProduct.tags,
          ImageURL: `https:${currentProduct.mainImage}`,
          URL: window.location.href,
          Brand: currentProduct.shopifyProduct.vendor,
          Price: getFromPrice(currentProduct.shopifyProduct.variants.edges),
        };
        _learnq.push(["track", "Added to Cart (react)", item]);
      }
    }
  }, [adding]);

  // Klaviyo Subscribe to OOS event
  useEffect(() => {
    if (oosSuccess) {
      if (isTracking && typeof _learnq === `object`) {
        const optionsMap = mapVariantOptions(currentProduct, currentVariant);
        const variantItem = {
          VariantId: decodeId(currentVariant.id),
          Price: currentVariant.price.amount,
          Quantity: currentQuantity || 1,
          ...(optionsMap.size ? { Size: optionsMap.size } : undefined),
          ...(optionsMap.color ? { Color: optionsMap.color } : undefined),
          ...(optionsMap.firmness
            ? { Firmness: optionsMap.firmness }
            : undefined),
          ...(optionsMap.value ? { Value: optionsMap.value } : undefined),
          Sku: currentVariant.sku,
          // Image: activeVariant.image,
          Url: `${window.location.origin}${window.location.pathname}${
            Object.entries(optionsMap).length ? "?" : ""
          }${Object.entries(optionsMap)
            .map(([k, v]) => `${k}=${encodeURIComponent(v)}`)
            .join("&")}`,
          ProductName: currentProduct.shopifyProduct.title,
        };
        _learnq.push([
          "identify",
          {
            $email: oosSuccess,
          },
        ]);
        _learnq.push(["track", "Subscribe To OOS (react, embed)", variantItem]);
        updateOosSuccess(null);
      }
    }
  }, [oosSuccess]);

  if (isTracking) {
    return (
      <Helmet
        script={
          ([
            {
              // Facebook Base Code
              type: "text/javascript",
              innerHTML: `!function(f,b,e,v,n,t,s){if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};if(!f._fbq)f._fbq=n;
            n.push=n;n.loaded=!0;n.version='2.0';n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t,s)}(window,
            document,'script','/fbevents.js');
            fbq.disablePushState = true;
            fbq('dataProcessingOptions', ['LDU'], 0, 0);
            fbq('init', '${fbId}'); // Insert your pixel ID here.
            fbq("track", "PageView");`,
            },
          ],
          [
            {
              // Pinterest Base Code
              type: "text/javascript",
              innerHTML: `!function(e){if(!window.pintrk){window.pintrk = function () {
            window.pintrk.queue.push(Array.prototype.slice.call(arguments))};var
              n=window.pintrk;n.queue=[],n.version="3.0";var
              t=document.createElement("script");t.async=!0,t.src=e;var
              r=document.getElementsByTagName("script")[0];
              r.parentNode.insertBefore(t,r)}}("https://s.pinimg.com/ct/core.js");
            pintrk('load', '${pintId}');
            pintrk('page');`,
            },
          ])
        }
        onChangeClientState={() => {
          if (typeof fbq === `function`) {
            setFBPixelLoaded(true);
          }
          if (typeof pintrk === `function`) {
            setPintPixelLoaded(true);
          }
        }}
      />
    );
  }
  return null;
}

BuffyTracking.propTypes = {
  fbId: PropTypes.string.isRequired,
  pintId: PropTypes.string.isRequired,
  layout: PropTypes.string.isRequired,
};

export default BuffyTracking;
