// React & Props
import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import useComponentSize from "@rehooks/component-size";

// Internal Components
import CaretIcon from "../atoms/icons/caret";

// import LatticeGrid from "lattice/lib/components/LatticeGrid";
// import SEO from "../components/functional/seo";

function Accordion({
  title,
  children,
  className,
  isHtml,
  onOpen,
  onClose,
  titleRoute,
  showOnInit,
  iconColor,
  props,
  tag,
}) {
  // const [showContent, setShowContent] = useState(false);
  const Tag = tag;
  const accordionProps = props;
  const [contentState, setContentState] = useState(
    showOnInit ? "show" : "init",
  );
  const contentRef = useRef();
  const contentSize = useComponentSize(contentRef);
  let accordionClasses = ["accordion"];
  if (className) {
    accordionClasses = accordionClasses.concat(
      Array.isArray(className) ? className : className.split(" "),
    );
  }

  let accordionPreview = null;
  if (
    accordionProps &&
    accordionProps.preview &&
    typeof accordionProps.preview !== "boolean"
  ) {
    accordionPreview = (
      <button
        className="contentful-preview"
        type="button"
        onClick={accordionProps.preview}>
        preview
      </button>
    );
  }

  let accordionContent = children;
  if (isHtml) {
    accordionContent = (
      <div
        className="accordion__content-list"
        ref={contentRef}
        dangerouslySetInnerHTML={{
          __html: children,
        }}
      />
    );
  } else if (typeof children === "string") {
    accordionContent = (
      <p ref={contentRef} className="accordion__content-text">
        {children}
      </p>
    );
  } else {
    accordionContent = { ...children, ref: contentRef };
  }

  useEffect(() => {
    if (contentState === "show" && onOpen) {
      onOpen();
    } else if (contentState === "hide" && onClose) {
      onClose();
    }
  }, [contentState]);

  const toggleAccordion = () => {
    if (contentState === "show") {
      setContentState("hide");
    } else {
      setContentState("show");
    }
  };

  return (
    <div role="tree" className={accordionClasses.join(" ")}>
      {accordionPreview}
      <div
        className="accordion__header"
        onClick={titleRoute ? null : toggleAccordion}
        onKeyPress={titleRoute ? null : toggleAccordion}
        role="button"
        tabIndex={0}>
        <Tag className="accordion__title">
          {titleRoute ? (
            <a href={titleRoute} className="accordion__link">
              {title}
            </a>
          ) : (
            title
          )}
        </Tag>
        <CaretIcon
          onClick={toggleAccordion}
          className={`${contentState === "show" ? "caret--active" : ""}`}
          color={iconColor}
        />
      </div>
      <div
        role="treeitem"
        className={`accordion__content accordion__content--${contentState}`}
        style={
          contentState === "show" ? { maxHeight: contentSize.height } : {}
        }>
        {accordionContent}
      </div>
    </div>
  );
}

Accordion.propTypes = {
  title: PropTypes.string.isRequired,
  tag: PropTypes.string,
  children: PropTypes.node.isRequired,
  isHtml: PropTypes.bool,
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  titleRoute: PropTypes.string,
  showOnInit: PropTypes.bool,
  iconColor: PropTypes.string,
  props: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.func,
      PropTypes.bool,
      PropTypes.number,
      PropTypes.objectOf(
        PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.func,
          PropTypes.bool,
          PropTypes.number,
          PropTypes.object,
        ]),
      ),
      PropTypes.arrayOf(PropTypes.any),
    ]),
  ),
};

Accordion.defaultProps = {
  isHtml: false,
  className: "",
  onOpen: null,
  onClose: null,
  titleRoute: null,
  showOnInit: false,
  iconColor: "defaultText",
  props: {},
  tag: "h6",
};

export default Accordion;
