// React & Props
import React from "react";
import PropTypes from "prop-types";
// import { getLCP, getCLS } from "web-vitals";
// import loadable from "@loadable/component";

// Lattice Components
import Lattice from "lattice/lib/components/Lattice";
import LatticeGrid from "lattice/lib/components/LatticeGrid";
import LatticeDevLines from "lattice/lib/components/LatticeDevLines";

// Internal Components
import Header from "../components/organisms/header";
import Footer from "../components/organisms/footer";
import DesignHeader from "../components/organisms/design-header";
import BuffyTracking from "../components/functional/tracking";

import CONSTANTS from "../constants";

// Providers
import ContextProvider from "../provider/contextProvider";
import ShopifyContextProvider from "../provider/shopifyContextProvider";

import StoreContext from "../context/storeContext";
import ShopifyContext from "../context/shopifyContext";

// Fixtures
import Menus from "../fixtures/menus.json";

// Styles
// import "../styles/preload.scss";
import "../styles/design-layout.scss";
import * as headerStyles from "../styles/header-styles.module.scss";

// const Preload = loadable(() => import("../components/functional/preload"));

const devLines =
  process.env.DEV_LINES && process.env.DEV_LINES.toLowerCase() === "true";

const Layout = ({ children, pageContext }) => {
  // getLCP(console.log, true);
  // getCLS(console.log, true);
  let content = null;
  if (pageContext.layout === "design") {
    content = (
      <ContextProvider>
        <ShopifyContextProvider layout={pageContext.layout}>
          {/* <Preload /> */}
          <Lattice
            className={`buffy ${pageContext.layout}`}
            hideOverflow={devLines}>
            {devLines ? <LatticeDevLines maxRows={1000} /> : null}
            <LatticeGrid
              className="design"
              innerGrid
              fullGrid
              fullWidth
              useGutters>
              <DesignHeader menu={pageContext.menu} current={pageContext.id} />
              {children}
            </LatticeGrid>
          </Lattice>
        </ShopifyContextProvider>
      </ContextProvider>
    );
  } else if (pageContext.layout === "demo") {
    content = (
      <ContextProvider>
        <ShopifyContextProvider layout={pageContext.layout}>
          {/* <Preload /> */}
          {children}
        </ShopifyContextProvider>
      </ContextProvider>
    );
  } else if (
    pageContext.layout === "home" ||
    pageContext.layout === "product" ||
    pageContext.layout === "page" ||
    pageContext.layout === "collection" ||
    pageContext.layout === "product-listing"
  ) {
    const data = pageContext.menus || Menus.data;
    const headerInfo = pageContext.page || {
      transparentHeader: false,
      headerMode: "dark",
    };
    content = (
      <ContextProvider>
        <ShopifyContextProvider layout={pageContext.layout}>
          {/* <Preload /> */}
          <BuffyTracking
            fbId={process.env.FACEBOOK_PIXEL_ID}
            pintId={process.env.PINTEREST_PIXEL_ID}
            layout={pageContext.layout}
          />
          <Lattice
            className={`buffy ${pageContext.layout}`}
            hideOverflow={devLines}>
            {devLines ? <LatticeDevLines maxRows={1000} /> : null}
            <Header
              menus={data && data.contentfulMenus.edges}
              transparent={headerInfo.transparentHeader}
              color={headerInfo.headerMode || "dark"}
              layout={pageContext.layout}
              announcement={headerInfo.announcement}
              reviews={
                pageContext.productReviews && pageContext.productReviews.details
              }
              cart={false}
              extras={{
                storeContext: StoreContext,
                shopifyContext: ShopifyContext,
                headerStyles,
                ...CONSTANTS,
                domain: process.env.DOMAIN,
                OOS_EMAIL_LIST: process.env.OOS_EMAIL_LIST,
                PRE_LAUNCH_LIST: process.env.PRE_LAUNCH_LIST,
                FOOTER_EMAIL_LIST: process.env.FOOTER_EMAIL_LIST,
                KLAVIYO_API_KEY: process.env.KLAVIYO_API_KEY,
                DEV: process.env.DEV,
              }}
            />
            <main>{children}</main>
            <Footer
              menus={data && data.contentfulMenus.edges}
              email={false}
              extras={{
                domain: process.env.DOMAIN,
                DIR_MAP: CONSTANTS.DIR_MAP,
                CORS_PROXY: CONSTANTS.CORS_PROXY,
                KLAVIYO_API_KEY: process.env.KLAVIYO_API_KEY,
                DEV: process.env.DEV,
              }}
            />
          </Lattice>
        </ShopifyContextProvider>
      </ContextProvider>
    );
  } else {
    // 404 page
    content = children;
  }
  return content;
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  pageContext: PropTypes.shape({ layout: PropTypes.string }),
};

Layout.defaultProps = {
  pageContext: {},
};

export default Layout;
