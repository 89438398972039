import React from "react";
import PropTypes from "prop-types";

function Form({ onSubmit, label, className, forwardRef, title, children }) {
  let classList = ["shared__form"];

  classList = classList.concat(
    Array.isArray(className) ? className : className.split(" "),
  );
  return (
    <form
      className={classList.join(" ")}
      onSubmit={onSubmit}
      ref={forwardRef}
      aria-label={label}>
      {title ? <h4>{title}</h4> : null}
      {children}
    </form>
  );
}

Form.designSystemProps = {
  label: {
    propType: PropTypes.string,
    default: "Buffy Form",
    required: false,
    type: "string",
    description: "Accessible form label.",
  },
  onSubmit: {
    propType: PropTypes.func,
    default: () => {},
    required: false,
    type: "function",
    description: "Event handler on form submit.",
  },
  className: {
    type: ["string", "array"],
    description: "List of classes for this element.",
    propType: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.arrayOf(PropTypes.string),
    ]),
    default: "",
    required: false,
  },
  forwardRef: {
    type: ["function", "object"],
    description: "Ref to component or DOM element.",
    subProps: {
      current: {
        type: ["any"],
        description: "DOM node of Ref object",
        required: false,
        default: null,
      },
    },
    propType: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.shape({ current: PropTypes.any }),
    ]),
    default: null,
    required: false,
  },
  title: {
    type: "string",
    description: "Form title.",
    propType: PropTypes.string,
    default: null,
    required: false,
  },
  children: {
    type: "node",
    description: "Form contents.",
    propType: PropTypes.node,
    default: null,
    required: false,
  },
};

const propTypes = {};
const defaultProps = {};
Object.entries(Form.designSystemProps).map(([k, v]) => {
  if (v.propType) {
    propTypes[k] = v.propType;
  }
  if (v.default || typeof v.default !== "undefined") {
    defaultProps[k] = v.default;
  }
  return false;
});
Form.propTypes = { ...propTypes };
Form.defaultProps = { ...defaultProps };

Form.displayName = "Form";

export default Form;
