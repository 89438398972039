import React from "react";
import PropTypes from "prop-types";

function CartIcon({ color, className, onClick, width }) {
  let iconClasses = ["shared__icon", "cart-icon"];
  if (className) {
    iconClasses = iconClasses.concat(
      Array.isArray(className) ? className : className.split(" "),
    );
  }

  return (
    <svg
      className={iconClasses.join(" ")}
      onClick={onClick}
      onKeyPress={onClick}
      width={`${width}px`}
      height={`${width}px`}
      viewBox={`0 0 ${width} ${width}`}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink">
      <title>Cart</title>
      <g id="Cart" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <path
          d="M7.9992231,18 C9.10382411,18 9.99928015,18.8954305 9.99928015,20 C9.99928015,21.1045695 9.10382411,22 7.9992231,22 C6.89462209,22 5.99916605,21.1045695 5.99916605,20 C5.99916605,18.8954305 6.89462209,18 7.9992231,18 Z M16.9994798,18 C18.1040809,18 18.9995369,18.8954305 18.9995369,20 C18.9995369,21.1045695 18.1040809,22 16.9994798,22 C15.8948788,22 14.9994228,21.1045695 14.9994228,20 C14.9994228,18.8954305 15.8948788,18 16.9994798,18 Z M3.99910899,2 C4.4259786,2 4.80105984,2.27008075 4.94125741,2.66458912 L4.97353199,2.77515787 L5.717,6 L19.9995654,6 C20.5993562,6 21.0545307,6.52069506 20.9947143,7.10037092 L20.9757747,7.21695569 L18.9754735,16.2169557 C18.8737859,16.6744812 18.4679666,17 17.9992642,17 L17.9992642,17 L6.99895043,17 C6.53022872,17 6.12439809,16.6744549 6.02272991,16.2169052 L6.02272991,16.2169052 L6.018,16.198 L3.20306285,4 L1,4 L1,2 L3.99910899,2 Z M18.7525064,8 L6.24514963,8 L7.80019399,15 L17.196462,15 L18.7525064,8 Z"
          className={`shared__fill-${color}`}
          fillRule="nonzero"
        />
      </g>
    </svg>
  );
}

CartIcon.designSystemProps = {
  color: {
    type: "string",
    description: "Design system color of icon.",
    default: "defaultText",
    propType: PropTypes.string,
    required: false,
  },
  width: {
    type: "number",
    description: "Icon width in pixels.",
    default: 32,
    propType: PropTypes.nuber,
    required: false,
  },
  onClick: {
    type: "function",
    description: "Function that executes when clicked.",
    required: false,
    default: () => {},
    propType: PropTypes.func,
  },
  className: {
    type: ["string", "array"],
    description: "List of classes for this element.",
    required: false,
    default: "",
    propType: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.arrayOf(PropTypes.string),
    ]),
  },
};

const propTypes = {};
const defaultProps = {};
Object.entries(CartIcon.designSystemProps).map(([k, v]) => {
  if (v.propType) {
    propTypes[k] = v.propType;
  }
  if (v.default || typeof v.default !== "undefined") {
    defaultProps[k] = v.default;
  }
  return false;
});
CartIcon.propTypes = { ...propTypes };
CartIcon.defaultProps = { ...defaultProps };

CartIcon.displayName = "CartIcon";

export default CartIcon;
