import React, { useState, useEffect, useRef, useContext } from "react";
import PropTypes from "prop-types";
import loadable from "@loadable/component";
import { useQueryParams, StringParam } from "use-query-params";
// import { OutboundLink } from "gatsby-plugin-google-analytics";
// import { GatsbyImage, getImage } from "gatsby-plugin-image";

// Lattice Components
import LatticeGrid from "lattice/lib/components/LatticeGrid";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";

// Internal Components
// import MediaBlock from "./media-block";
import BuffyLogo from "../atoms/icons/buffy-logo";
import CartIcon from "../atoms/icons/cart-icon";
import HamburgerIcon from "../atoms/icons/hamburger-icon";
import useHover from "../../hooks/hover";
import useScrollPosition from "../../hooks/useScrollPosition";
import useHash from "../../hooks/useHash";
import usePrevious from "../../hooks/usePrevious";
import BuffyMenu from "../atoms/menu";
// import Button from "../atoms/button";
// import Modal from "./modal";
// import Form from "../molecules/form";
// import Input from "../atoms/input";
import Image from "../atoms/image";

// Utils
import {
  getLink,
  isGiftCardProduct,
  isAvailable,
  addProductToCart,
  corsFetch,
  calculateBundlePrice,
  getMediaBlockProps,
} from "../../utils";
import CONSTANTS from "../../constants";

import {
  getParams,
  getAvailableParams,
  getHeaderInfo,
} from "../../utils/page-info";

const MediaBlock = loadable(() =>
  import(
    /* webpackChunkName: 'quilt-src-components-organisms-media-block' */ "./media-block"
  ),
);
const Button = loadable(() =>
  import(
    /* webpackChunkName: 'quilt-src-components-atoms-button' */ "../atoms/button"
  ),
);
const Modal = loadable(() =>
  import(
    /* webpackChunkName: 'quilt-src-components-organisms-modal' */ "./modal"
  ),
);
const Form = loadable(() =>
  import(
    /* webpackChunkName: 'quilt-src-components-molecules-form' */ "../molecules/form"
  ),
);
const Input = loadable(() =>
  import(
    /* webpackChunkName: 'quilt-src-components-atoms-input' */ "../atoms/input"
  ),
);

function Header({
  menus,
  transparent,
  color,
  layout,
  reviews,
  announcement,
  discounts,
  defaultDiscount,
  cart,
  extras,
}) {
  const {
    store: {
      showOOS,
      showPreLaunch,
      showDiscount,
      shop: { currentProduct, currentVariant, currentBundle },
    },
    updateShowOOS,
    updateShowPreLaunch,
    updateShowDiscount,
    updateOosSuccess,
    updateShop,
    productCta,
    bundlePriceEl,
  } = useContext(extras.storeContext);

  const {
    store: { checkout, adding },
    addVariantToCart,
  } = useContext(extras.shopifyContext);

  const queryParams = getAvailableParams({
    sitewideAlternativeContent: extras && extras.sitewideAlternativeContent,
    alternativeContent: extras && extras.alternativeContent,
    discounts,
    menus,
    StringParam,
  });
  const [query] = useQueryParams(queryParams);
  const [params] = useState(
    getParams({
      query,
      discounts,
      defaultDiscount,
      sitewideAlternativeContent: extras && extras.sitewideAlternativeContent,
      alternativeContent: extras && extras.alternativeContent,
      menus,
    }),
  );
  const headerInfo = getHeaderInfo({
    params,
    alternativeContent: extras && extras.alternativeContent,
    sitewideAlternativeContent: extras && extras.sitewideAlternativeContent,
    menus,
    announcement,
    discounts,
    extras,
  });
  // ${
  //   processAltFuncUpdate ||
  //   abTestProcess ||
  //   (abTestProcess !== false && initTestProcess)
  //     ? `shared__ab-test`
  //     : ""
  // }

  const { headerStyles } = extras;
  const altExtras =
    headerInfo && headerInfo.constants
      ? { ...extras, ...headerInfo.constants }
      : extras;

  const [shopAllRef, shopAllEl, shopAllIsHovered] = useHover();
  const [navRef, navEl, navIsHovered] = useHover();
  // const [shopAllRef, shopAllIsHovered] = useHover();
  const [submenuRef, submenuEl, submenuIsHovered] = useHover(true);
  const [submenuState, setSubmenuState] = useState("init");
  const [submenuAnimateState, setSubmenuAnimateState] = useState(null);
  const [scrollPos, setScrollPos] = useState();
  const [atcScroll, setAtcScroll] = useState("init");
  const [bundlePriceScroll, setBundlePriceScroll] = useState("init");
  const mobileExpand = useRef();

  const [mainMenu, setMainMenu] = useState(headerInfo.mainMenu);
  const [utilMenu, setUtilMenu] = useState(headerInfo.utilMenu);
  const [visualMenu, setVisualMenu] = useState(headerInfo.visualMenu);
  const [categoryMenu1, setCategoryMenu1] = useState(headerInfo.categoryMenu1);
  const [categoryMenu2, setCategoryMenu2] = useState(headerInfo.categoryMenu2);

  const extrasShowDiscounts =
    extras &&
    extras.SHOW_DISCOUNTS &&
    extras.SHOW_DISCOUNTS.toLowerCase() === "true";
  const showDiscounts =
    extrasShowDiscounts === null || extrasShowDiscounts === undefined
      ? process.env.SHOW_DISCOUNTS &&
        process.env.SHOW_DISCOUNTS.toLowerCase() === "true"
      : extrasShowDiscounts;

  const discount =
    announcement &&
    showDiscounts &&
    discounts &&
    discounts.find(
      (d) =>
        d.node.shopifyDiscount &&
        d.node.shopifyDiscount.title === params.discount,
    );
  const discountAnnouncement =
    discount && discount.node.announcement
      ? {
          ...discount.node.announcement,
          ctaColor: discount.node.badgeColor,
          displayTitle: discount.node.marketingTitle,
        }
      : null;
  const [displayAnnouncement, setDisplayAnnouncement] = useState(
    discountAnnouncement ||
      headerInfo.announcement ||
      (headerInfo.header && headerInfo.header.announcement) ||
      announcement,
  );
  const [displayTransparent, setDisplayTransparent] = useState(
    (headerInfo.header && headerInfo.header.transparent) || transparent,
  );
  const [displayColor, setDisplayColor] = useState(
    (headerInfo.header && headerInfo.header.color) || color,
  );
  const [pageClasses, setPageClasses] = useState(headerInfo.classNames);
  const [optimizeLoaded, updateOptimizeLoaded] = useState(false);

  const runAltFunctions = (info) => {
    if (info.functions && info.functions.FUNC && info.functions.FUNC.header) {
      info.functions.FUNC.header();
    }
  };
  useEffect(() => {
    runAltFunctions(headerInfo);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      if (window.google_optimize && !optimizeLoaded) {
        clearInterval(interval);
        const optimizeParams = getParams({
          query,
          discounts,
          defaultDiscount,
          sitewideAlternativeContent:
            extras && extras.sitewideAlternativeContent,
          alternativeContent: extras && extras.alternativeContent,
          menus,
          optimize: true,
        });
        if (
          optimizeParams &&
          Object.keys(optimizeParams).find((k) =>
            k.includes(CONSTANTS.OPTIMIZE_PREFIX),
          )
        ) {
          const optimizeHeaderInfo = getHeaderInfo({
            params: optimizeParams,
            alternativeContent: extras && extras.alternativeContent,
            sitewideAlternativeContent:
              extras && extras.sitewideAlternativeContent,
            menus,
            announcement,
            discounts,
            extras,
          });

          setMainMenu(optimizeHeaderInfo.mainMenu);
          setUtilMenu(optimizeHeaderInfo.utilMenu);
          setVisualMenu(optimizeHeaderInfo.visualMenu);
          setCategoryMenu1(optimizeHeaderInfo.categoryMenu1);
          setCategoryMenu2(optimizeHeaderInfo.categoryMenu2);

          setDisplayAnnouncement(
            discountAnnouncement ||
              optimizeHeaderInfo.announcement ||
              (optimizeHeaderInfo.header &&
                optimizeHeaderInfo.header.announcement) ||
              announcement,
          );
          setDisplayTransparent(
            (optimizeHeaderInfo.header &&
              optimizeHeaderInfo.header.transparent) ||
              transparent,
          );
          setDisplayColor(
            (optimizeHeaderInfo.header && optimizeHeaderInfo.header.color) ||
              color,
          );
          setPageClasses(optimizeHeaderInfo.classNames);

          runAltFunctions(optimizeHeaderInfo);
        }
        updateOptimizeLoaded(true);
      }
    }, 10);
    return () => clearInterval(interval);
  }, []);

  const extrasDev = extras && extras.DEV && extras.DEV.toLowerCase() === "true";
  const isDev =
    extrasDev === null || extrasDev === undefined
      ? process.env.DEV && process.env.DEV.toLowerCase() === "true"
      : extrasDev;

  const [oosEmail, setOosEmail] = useState("");
  const [formMessage, setFormMessage] = useState({
    error: false,
    message: "",
    // mode: "init",
  });
  const [preLaunchEmail, setPreLaunchEmail] = useState("");
  const [preLaunchFormMessage, setPreLaunchFormMessage] = useState({
    error: false,
    message: "",
    // mode: "init",
  });
  const [discountEmail, setDiscountEmail] = useState("");
  const [discountFormMessage, setDiscountFormMessage] = useState({
    error: false,
    message: "",
    // mode: "init",
  });

  const [hash, setHash] = useHash();

  useScrollPosition(({ currPos }) => {
    setScrollPos(currPos.y);
  });

  if (layout === "product" || layout === "bundle") {
    useScrollPosition(
      ({ currPos }) => {
        if (currPos.y < 0) {
          setAtcScroll("hidden");
        } else if (atcScroll === "hidden") {
          setAtcScroll("show");
        }
      },
      [atcScroll],
      productCta,
    );
  }
  if (layout === "bundle") {
    useScrollPosition(
      ({ currPos }) => {
        if (currPos.y < 0) {
          setBundlePriceScroll("show");
        } else if (bundlePriceScroll === "show") {
          setBundlePriceScroll("hidden");
        }
      },
      [bundlePriceScroll],
      bundlePriceEl,
    );
  }

  const handleAnimationStart = () => {
    setSubmenuAnimateState("animating");
  };
  const handleAnimationEnd = () => {
    setSubmenuAnimateState("ending");
    if (submenuState === "hidden") {
      setSubmenuState("init");
    }
  };

  const handleMobileMenu = () => {
    if (submenuAnimateState === "animating") {
      return;
    }
    if (submenuState === "show") {
      setSubmenuState("hidden");
      if (hash === (extras && `#${extras.skipToMenuId}`)) {
        setHash("");
      }
    } else {
      setSubmenuState("show");
    }
  };

  const prevShopAllIsHovered = usePrevious(shopAllIsHovered);
  const prevSubmenuIsHovered = usePrevious(submenuIsHovered);
  useEffect(() => {
    // Set body overflow when submenu is shown or hidden
    if (submenuEl) {
      if (submenuState === "show") {
        disableBodyScroll(submenuEl);
      } else {
        enableBodyScroll(submenuEl);
      }
    }
    // Check if the mobile expand icons exists on page
    if (
      mobileExpand.current &&
      mobileExpand.current.getBoundingClientRect().top
    ) {
      if (
        hash === (extras && `#${extras.skipToMenuId}`) &&
        submenuState === "init"
      ) {
        setSubmenuState("show");
      }
      return;
    }
    if (submenuAnimateState !== null && submenuIsHovered) {
      return;
    }
    const skipToMenuOpen =
      hash === (extras && `#${extras.skipToMenuId}`) &&
      (shopAllIsHovered ||
        submenuIsHovered ||
        (!shopAllIsHovered &&
          !submenuIsHovered &&
          !prevShopAllIsHovered &&
          !prevSubmenuIsHovered));
    // keep open if hash matches menu and hover is not going from true to false
    if (shopAllIsHovered || submenuIsHovered || skipToMenuOpen) {
      setSubmenuState("show");
    } else if (submenuState !== "init") {
      setSubmenuState("hidden");
    }
    if (!skipToMenuOpen && hash === (extras && `#${extras.skipToMenuId}`)) {
      setHash("");
    }
  }, [
    shopAllIsHovered,
    submenuState,
    setSubmenuState,
    submenuIsHovered,
    hash,
    submenuEl,
  ]);

  // console.log("scroll pos and substate", scrollPos, submenuState, navIsHovered);

  // naviIsHovered for desktop, submenuState

  const isMobile =
    mobileExpand.current && mobileExpand.current.getBoundingClientRect().top;
  const subnavState = !navIsHovered || (submenuState === "init" && isMobile);

  const headerLogoRoute = getLink({
    item: null,
    extras,
  });
  // const LinkTag = headerLogoRoute.startsWith("http") ? OutboundLink : "a";
  const LinkTag = "a";

  const cartRoute = getLink({
    item: null,
    slug: "cart",
    extras,
  });
  // const CartLinkTag = cartRoute.startsWith("http") ? OutboundLink : "a";
  const CartLinkTag = "a";

  const shopAllRoute = getLink({
    item: mainMenu && mainMenu.items[0],
    extras,
  });
  // const ShopAllLinkTag = shopAllRoute.startsWith("http") ? OutboundLink : "a";
  const ShopAllLinkTag = "a";

  const isGiftCard = isGiftCardProduct(currentProduct);

  let atcText = isGiftCard
    ? altExtras.GIFT_CARD_BUTTON_TEXT
    : altExtras.TRY_NOW_BUTTON_TEXT;
  let atcColor = altExtras.TRY_NOW_BUTTON_COLOR;
  let atcActive = true;
  if (currentProduct && currentProduct.preLaunch) {
    atcText = altExtras.PRE_LAUCH_BUTTON_TEXT;
    atcActive = false;
  }
  if (
    currentVariant &&
    currentProduct &&
    !isAvailable(currentVariant, extras.OOS_QUANTITY, currentProduct)
  ) {
    atcText = altExtras.UNAVAILABLE_BUTTON_TEXT;
    atcColor = altExtras.OOS_BUTTON_COLOR;
    atcActive = false;
  }
  if (currentBundle && !currentBundle.upsell) {
    if (!currentBundle.started) {
      atcText = altExtras.BUNDLE_START_TEXT;
    } else if (currentBundle.index !== null && currentBundle.items) {
      // OOS of bundle item
      if (
        currentBundle.items[currentBundle.index].tempVariant &&
        currentBundle.items[currentBundle.index].product &&
        !isAvailable(
          currentBundle.items[currentBundle.index].tempVariant,
          extras.OOS_QUANTITY,
          currentBundle.items[currentBundle.index].product,
        )
      ) {
        atcText = altExtras.UNAVAILABLE_BUTTON_TEXT;
        atcColor = altExtras.OOS_BUTTON_COLOR;
        atcActive = false;
      } else {
        const inReview = currentBundle.items.every(
          (i, index) =>
            currentBundle.index === index ||
            (currentBundle.index !== index && i.variant),
        );
        atcText = inReview
          ? altExtras.BUNDLE_REVIEW_TEXT
          : altExtras.BUNDLE_NEXT_TEXT;
      }
    } else {
      atcText = altExtras.TRY_NOW_BUTTON_TEXT;
    }
  }
  if (
    !currentProduct &&
    (!currentBundle || (currentBundle && currentBundle.upsell))
  ) {
    atcText = altExtras.PRELOAD_TEXT;
    atcActive = false;
  }

  let atcFunc = () => {};
  if (currentProduct && currentProduct.preLaunch) {
    atcFunc = (e) => {
      e.preventDefault();
      if (updateShowPreLaunch) {
        updateShowPreLaunch(true);
      }
    };
  } else if (
    !currentProduct &&
    (!currentBundle || (currentBundle && currentBundle.upsell))
  ) {
    atcFunc = (e) => {
      e.preventDefault();
      if (updateShowDiscount) {
        updateShowDiscount(true);
      }
    };
  } else if (!atcActive) {
    atcFunc = (e) => {
      e.preventDefault();
      if (updateShowOOS) {
        updateShowOOS(true);
      }
    };
  } else if (currentBundle && !currentBundle.upsell) {
    // OOS Func
    if (atcText === altExtras.UNAVAILABLE_BUTTON_TEXT) {
      atcFunc = (e) => {
        e.preventDefault();
        if (updateShowOOS) {
          updateShowOOS(true);
        }
      };
    }
    // Start Bundling
    if (atcText === altExtras.BUNDLE_START_TEXT) {
      atcFunc = (e) => {
        e.preventDefault();
        const bundleCopy = { ...currentBundle };
        bundleCopy.started = true;
        bundleCopy.index = 0;
        // bundleCopy.items[0].open = true;
        updateShop({ currentBundle: bundleCopy }, true);
      };
    }
    // Confirm Selection or Review Bundle
    if (
      atcText === altExtras.BUNDLE_NEXT_TEXT ||
      atcText === altExtras.BUNDLE_REVIEW_TEXT
    ) {
      atcFunc = (e) => {
        e.preventDefault();
        const bundleCopy = { ...currentBundle };
        bundleCopy.items[currentBundle.index].variant =
          bundleCopy.items[currentBundle.index].tempVariant;

        // Open first unselected product
        const unselectedIndex = bundleCopy.items.findIndex((i) => !i.variant);
        bundleCopy.index = unselectedIndex >= 0 ? unselectedIndex : null;
        updateShop({ currentBundle: bundleCopy }, true);
      };
    }
    // Add to Cart
    if (atcText === altExtras.TRY_NOW_BUTTON_TEXT) {
      atcFunc = (e) => {
        e.preventDefault();
        if (!adding) {
          const bundleCopy = { ...currentBundle };
          bundleCopy.items = bundleCopy.items.filter((i) => i.variant);
          addProductToCart({
            e,
            addVariantToCart,
            currentBundle: bundleCopy,
            oosQuantity: extras.OOS_QUANTITY,
            altProps:
              headerInfo.functions &&
              headerInfo.functions.CART &&
              headerInfo.functions.CART.props,
          });
        }
      };
    }
  } else if (currentBundle && currentBundle.upsell) {
    atcFunc = (e) => {
      e.preventDefault();
      if (!adding) {
        const bundleCopy = { ...currentBundle };
        bundleCopy.items = bundleCopy.items.filter((i) => i.tempVariant);
        addProductToCart({
          e,
          addVariantToCart,
          currentVariant,
          currentProduct,
          currentBundle: bundleCopy,
          oosQuantity: extras.OOS_QUANTITY,
          altProps:
            headerInfo.functions &&
            headerInfo.functions.CART &&
            headerInfo.functions.CART.props,
        });
      }
    };
  } else {
    atcFunc = (e) => {
      e.preventDefault();
      if (!adding) {
        addProductToCart({
          e,
          addVariantToCart,
          currentVariant,
          currentProduct,
          oosQuantity: extras.OOS_QUANTITY,
          altProps:
            headerInfo.functions &&
            headerInfo.functions.CART &&
            headerInfo.functions.CART.props,
        });
      }
    };
  }
  const handleSubmit = (e) => {
    e.preventDefault();

    const apiReturn = corsFetch({
      url: `https://a.klaviyo.com/api/v2/list/${
        extras.OOS_EMAIL_LIST || process.env.OOS_EMAIL_LIST
      }/subscribe`,
      corsProxy: extras.CORS_PROXY || CONSTANTS.CORS_PROXY,
      dev: isDev,
      data: {
        api_key: extras.KLAVIYO_API_KEY || process.env.KLAVIYO_API_KEY,
        profiles: [
          {
            email: oosEmail,
          },
        ],
      },
    });

    apiReturn
      .then(() => {
        setFormMessage({
          error: false,
          message: "Thanks!",
          // mode: "show",
        });
        updateOosSuccess(oosEmail);
      })
      .catch((error) => {
        let message = "";
        if (error.response) {
          message = error.response.data.detail || error.response.data.message;
          // console.log(error.response.data);
          // console.log(error.response.status);
          // console.log(error.response.headers);
        } else if (error.request) {
          // console.log(error.request);
        } else {
          message = error.message;
        }
        // console.log("error message", message);
        // console.log(error.config);
        setFormMessage({
          error: true,
          message: message || "Oops, there was an error. Try again.",
          // mode: "show",
        });
      });
  };

  let formStatus = "";
  if (formMessage.error) {
    formStatus = "error";
  } else if (formMessage.message) {
    formStatus = "success";
  }

  const handlePreLaunchSubmit = (e) => {
    e.preventDefault();
    const apiReturn = corsFetch({
      url: `https://a.klaviyo.com/api/v2/list/${
        extras.PRE_LAUNCH_LIST || process.env.PRE_LAUNCH_LIST
      }/subscribe`,
      corsProxy: extras.CORS_PROXY || CONSTANTS.CORS_PROXY,
      dev: isDev,
      data: {
        api_key: extras.KLAVIYO_API_KEY || process.env.KLAVIYO_API_KEY,
        profiles: [
          {
            email: preLaunchEmail,
          },
        ],
      },
    });

    apiReturn
      .then(() => {
        setPreLaunchFormMessage({
          error: false,
          message: "Thanks!",
          // mode: "show",
        });
      })
      .catch((error) => {
        let message = "";
        if (error.response) {
          message = error.response.data.detail || error.response.data.message;
        } else if (error.request) {
          // console.log(error.request);
        } else {
          message = error.message;
        }
        setPreLaunchFormMessage({
          error: true,
          message: message || "Oops, there was an error. Try again.",
        });
      });
  };
  let preLaunchFormStatus = "";
  if (preLaunchFormMessage.error) {
    preLaunchFormStatus = "error";
  } else if (preLaunchFormMessage.message) {
    preLaunchFormStatus = "success";
  }

  const handleDiscountSubmit = (e) => {
    e.preventDefault();
    const apiReturn = corsFetch({
      url: `https://a.klaviyo.com/api/v2/list/${
        extras.FOOTER_EMAIL_LIST || process.env.FOOTER_EMAIL_LIST
      }/subscribe`,
      corsProxy: extras.CORS_PROXY || CONSTANTS.CORS_PROXY,
      dev: isDev,
      data: {
        api_key: extras.KLAVIYO_API_KEY || process.env.KLAVIYO_API_KEY,
        profiles: [
          {
            email: discountEmail,
          },
        ],
      },
    });

    apiReturn
      .then(() => {
        setDiscountFormMessage({
          error: false,
          message: "Here's your $10 off code: WELCOME10",
          // mode: "show",
        });
      })
      .catch((error) => {
        let message = "";
        if (error.response) {
          message = error.response.data.detail || error.response.data.message;
        } else if (error.request) {
          // console.log(error.request);
        } else {
          message = error.message;
        }
        setDiscountFormMessage({
          error: true,
          message: message || "Oops, there was an error. Try again.",
        });
      });
  };
  let discountFormStatus = "";
  if (discountFormMessage.error) {
    discountFormStatus = "error";
  } else if (discountFormMessage.message) {
    discountFormStatus = "success";
  }

  const bundleInitialized =
    currentBundle &&
    currentBundle.items &&
    currentBundle.items.every((i) => i.product);
  const bundlePriceHeader =
    layout === "bundle" && bundleInitialized
      ? calculateBundlePrice(currentBundle, params)
      : {};

  const usePriceInfo =
    layout === "bundle" &&
    currentBundle &&
    params &&
    currentBundle.items.every(
      (i) =>
        i.priceInfo &&
        i.priceInfo.newPrice &&
        (i.priceInfo.discountName || i.priceInfo.bundleDiscountName) &&
        i.priceInfo.valid &&
        i.priceInfo.newPrice !== i.priceInfo.price &&
        !i.priceInfo.skipDisplay,
    );

  const isPreview = Boolean(extras.preview);
  return (
    <>
      {(showOOS && currentVariant && layout === "product") ||
      (showOOS &&
        currentBundle &&
        currentBundle.index !== null &&
        currentBundle.items[currentBundle.index].tempVariant &&
        layout === "bundle") ? (
        <Modal
          handleClose={() => updateShowOOS(false)}
          className={`oos__modal ${pageClasses ? pageClasses.join(" ") : ""}`}>
          {currentVariant ? (
            <h4>{currentVariant.title.split(" / ").join(", ")}</h4>
          ) : null}
          {currentBundle && !currentBundle.upsell ? (
            <div className="oos-bundle-heading">
              <h4>
                {currentBundle.items[currentBundle.index].product.heading ||
                  currentBundle.items[currentBundle.index].product.title}
              </h4>
              {currentBundle.items[
                currentBundle.index
              ].product.shopifyProduct.options.map((o) => (
                <p
                  key={`oos-option-${o.name.toLowerCase()}`}
                  className="oos-option">
                  {o.name}:{" "}
                  {o.values.find((v) =>
                    currentBundle.items[currentBundle.index].tempVariant.title
                      .split(" / ")
                      .includes(v),
                  )}
                </p>
              ))}
            </div>
          ) : null}
          <p>Get notified when this product is back in stock</p>
          <Form onSubmit={handleSubmit} label="Buffy Out Of Stock Form">
            <Input
              placeholder="Enter Email"
              title="Email"
              type="email"
              name="email"
              id="oosEmail"
              color="eucalyptus"
              onChange={(e) => {
                setOosEmail(e.target.value);
              }}
              value={oosEmail}
            />
            <Button color={atcColor} submit>
              {altExtras.UNAVAILABLE_BUTTON_TEXT}
            </Button>
            {formStatus === "error" ? (
              <p className="form__email-error">{formMessage.message}</p>
            ) : null}
            {formStatus === "success" ? (
              <p className="form__email-success">{formMessage.message}</p>
            ) : null}
          </Form>
        </Modal>
      ) : null}
      {showDiscount &&
      !currentProduct &&
      (layout === "product" || layout === "bundle") ? (
        <Modal
          handleClose={() => updateShowDiscount(false)}
          className={`oos__modal ${pageClasses ? pageClasses.join(" ") : ""}`}>
          <h4>{altExtras.DISCOUNT_COPY}</h4>
          <Form onSubmit={handleDiscountSubmit} label="Buffy $10 Discount">
            <Input
              placeholder="Enter Email"
              title="Email"
              type="email"
              name="email"
              id="discountEmail"
              color="eucalyptus"
              onChange={(e) => {
                setDiscountEmail(e.target.value);
              }}
              value={discountEmail}
            />
            <Button color={atcColor} submit>
              {altExtras.DISCOUNT_BUTTON_TEXT}
            </Button>
            {discountFormStatus === "error" ? (
              <p className="form__email-error">{discountFormMessage.message}</p>
            ) : null}
            {discountFormStatus === "success" ? (
              <p className="form__email-success">
                {discountFormMessage.message}
              </p>
            ) : null}
          </Form>
        </Modal>
      ) : null}
      {showPreLaunch &&
      currentProduct &&
      currentProduct.preLaunch &&
      layout === "product" ? (
        <Modal
          handleClose={() => updateShowPreLaunch(false)}
          className={`oos__modal ${pageClasses ? pageClasses.join(" ") : ""}`}>
          <h4>{currentProduct.title}</h4>
          <p>{altExtras.PRE_LAUNCH_COPY}</p>
          <Form
            onSubmit={handlePreLaunchSubmit}
            label="Buffy Pre Launch Waitlist">
            <Input
              placeholder="Enter Email"
              title="Email"
              type="email"
              name="email"
              id="preLaunchEmail"
              color="eucalyptus"
              onChange={(e) => {
                setPreLaunchEmail(e.target.value);
              }}
              value={preLaunchEmail}
            />
            <Button color={atcColor} submit>
              {altExtras.PRE_LAUCH_BUTTON_TEXT}
            </Button>
            {preLaunchFormStatus === "error" ? (
              <p className="form__email-error">
                {preLaunchFormMessage.message}
              </p>
            ) : null}
            {preLaunchFormStatus === "success" ? (
              <p className="form__email-success">
                {preLaunchFormMessage.message}
              </p>
            ) : null}
          </Form>
        </Modal>
      ) : null}
      {displayAnnouncement ? (
        <>
          {!optimizeLoaded && extras && extras.activeOptimizeTest ? (
            <div className="shared__ab-test" />
          ) : null}
          <MediaBlock
            type={displayAnnouncement.type}
            className={`${scrollPos < 0 ? "mediablock__pencilbar--init" : ""}${
              scrollPos < -1 * headerStyles.navPencilbarHeight
                ? " mediablock__pencilbar--scroll"
                : ""
            }${pageClasses ? ` ${pageClasses.join(" ")}` : ""}`}
            {...getMediaBlockProps({
              ...displayAnnouncement,
              preview: extras && extras.preview,
              previewId: displayAnnouncement.id,
            })}
            key={displayAnnouncement.id}
          />
        </>
      ) : null}
      <LatticeGrid
        fullWidth
        fullGrid
        innerGrid
        useGutters
        className={`topnav${displayTransparent ? " topnav--transparent" : ""}${
          scrollPos < 0 ? " topnav--scroll" : ""
        }${submenuState === "show" ? " topnav--show" : ""}${
          displayColor === "light" ? " topnav--light" : ""
        }${displayAnnouncement ? " topnav--pencilbar" : ""}${
          scrollPos < -1 * headerStyles.navPencilbarHeight
            ? " topnav--pencilbar-scroll"
            : ""
        }${pageClasses ? ` ${pageClasses.join(" ")}` : ""}`}
        tag="header"
        forwardRef={navRef}>
        <div className="topnav__expand">
          {mainMenu &&
            mainMenu.items.map((item, index) => {
              const topNavItemRoute = getLink({
                item,
                extras,
              });
              // const TopNavLinkTag = topNavItemRoute.startsWith("http")
              //   ? OutboundLink
              //   : "a";
              const TopNavLinkTag = "a";
              return (
                <div
                  className={`topnav__expand-item topnav__expand-item--${
                    reviews && reviews.numReviews ? atcScroll : "init"
                  }`}
                  key={item.id}
                  ref={index === 0 ? shopAllRef : null}>
                  {index === 0 ? (
                    <p className="topnav__expand-text">{item.title}</p>
                  ) : (
                    <p className="topnav__expand-text">
                      <TopNavLinkTag href={topNavItemRoute}>
                        {item.title}
                      </TopNavLinkTag>
                    </p>
                  )}
                </div>
              );
            })}
          {layout === "product" &&
          currentProduct &&
          reviews &&
          reviews.numReviews ? (
            <a
              className={`topnav__review topnav__review--${atcScroll}`}
              href="#reviewAnchor"
              title={`Reviews (${reviews.score})`}>
              <h6>{currentProduct.heading}</h6>
              <div className="topnav__review-score">
                <div className="topnav__review-stars">
                  <Image
                    image={extras.reviewStars}
                    alt="Review stars."
                    legacy={isPreview}
                  />
                </div>
                <p>
                  {reviews.score} / {reviews.numReviews.toLocaleString()}{" "}
                  reviews
                </p>
              </div>
            </a>
          ) : null}

          <div
            className={`topnav__expand-border topnav__expand-border--${submenuState}`}
          />
          <div
            className={`topnav__mobile-expand-wrapper${
              !mainMenu ? " topnav__mobile-expand-wrapper--hide" : ""
            }`}
            onClick={handleMobileMenu}
            ref={mobileExpand}
            role="button"
            tabIndex={0}
            onKeyPress={handleMobileMenu}>
            {submenuState !== "init" ? (
              <div className="topnav__mobile-close" />
            ) : (
              <HamburgerIcon
                color={
                  displayColor === "light" && !scrollPos && subnavState
                    ? "standardWhite"
                    : "defaultText"
                }
              />
            )}
          </div>
        </div>
        <div className="topnav__logo-wrapper">
          <LinkTag
            href={headerLogoRoute}
            className={`topnav__logo topnav__logo--${atcScroll}`}>
            <BuffyLogo
              color={
                displayColor === "light" && !scrollPos && subnavState
                  ? "standardWhite"
                  : "defaultText"
              }
              title={layout === "home" ? "Buffy" : "Home"}
            />
            {layout === "home" ? "Buffy" : "Home"}
          </LinkTag>
          {layout === "product" || layout === "bundle" ? (
            <div className={`topnav__atc topnav__atc--${atcScroll}`}>
              <Button
                className="topnav__atc-button"
                inactive={
                  !currentVariant &&
                  currentProduct &&
                  !currentProduct.preLaunch &&
                  currentProduct
                }
                color={atcColor}
                onClick={atcFunc}
                label={`${
                  (currentProduct && `${currentProduct.heading} `) ||
                  (currentBundle &&
                    !currentBundle.upsell &&
                    currentBundle.index &&
                    `${currentBundle.heading} `)
                }Header`}>
                {atcText}
              </Button>
            </div>
          ) : null}
          {layout === "bundle" ? (
            <div
              className={`topnav__bundle-price topnav__extra topnav__extra--${
                currentBundle && !currentBundle.started
                  ? "init"
                  : bundlePriceScroll
              }`}>
              <p className="topnav__bundle-total-title">
                {altExtras.BUNDLE_PRICE_NAV_TITLE}
              </p>

              <div className="topnav__bundle-total-wrapper">
                {usePriceInfo ? (
                  <>
                    <del>${bundlePriceHeader.regularPrice}</del>
                    <ins>
                      $
                      {bundlePriceHeader.newPrice &&
                        bundlePriceHeader.newPrice.toFixed(2)}
                    </ins>
                  </>
                ) : (
                  <p className="topnav__bundle-total">
                    ${bundlePriceHeader.regularPrice}
                  </p>
                )}
              </div>
            </div>
          ) : null}
        </div>
        {/* <div
          className={`topnav__atc-wrapper topnav__atc-wrapper--${atcScroll}`}>
          <p>will be an atc button...</p>
        </div> */}
        <div className="topnav__util">
          {utilMenu ? (
            <BuffyMenu
              ulClass="shared__menu topnav__util-menu"
              liClass="topnav__menu-item"
              menu={utilMenu}
              extras={extras}
            />
          ) : null}
          {cart ? (
            <div className="topnav__cart-wrapper">
              <CartLinkTag href={cartRoute} className="topnav__cart">
                {checkout.lineItems.length ? (
                  <div className="cart__badge">
                    <span className="cart__badge-number">
                      {checkout.lineItems.length}
                    </span>
                  </div>
                ) : null}
                <CartIcon
                  color={
                    displayColor === "light" && !scrollPos && subnavState
                      ? "standardWhite"
                      : "defaultText"
                  }
                />
                Cart
              </CartLinkTag>
            </div>
          ) : null}
        </div>

        <div
          className={`subnav subnav--${submenuState} ${
            displayAnnouncement ? "subnav--pencilbar" : ""
          } ${scrollPos < 0 ? "subnav--scroll" : ""} ${
            scrollPos < -1 * headerStyles.navPencilbarHeight
              ? "subnav--pencilbar-scroll"
              : ""
          }`}
          ref={submenuRef}
          onAnimationStart={handleAnimationStart}
          onAnimationEnd={handleAnimationEnd}>
          {visualMenu
            ? visualMenu.items.map((item, vindex) => {
                const visualNavItemRoute = getLink({
                  item,
                  extras,
                });
                // const VisualNavLinkTag = visualNavItemRoute.startsWith("http")
                //   ? OutboundLink
                //   : "a";
                const VisualNavLinkTag = "a";
                return (
                  <VisualNavLinkTag
                    className={`${
                      visualMenu.classNames
                        ? visualMenu.classNames.join(" ")
                        : ""
                    } subnav__visual-item ${
                      visualMenu.items.length > 2 && vindex
                        ? "subnav__visual-item--condensed"
                        : ""
                    }`}
                    key={item.id}
                    onClick={handleMobileMenu}
                    onKeyPress={handleMobileMenu}
                    href={visualNavItemRoute}>
                    {extras.preview && typeof extras.preview !== "boolean" ? (
                      <button
                        className="contentful-preview"
                        type="button"
                        onClick={() => {
                          extras.preview.open(
                            visualMenu.id,
                            extras.preview.options,
                          );
                          extras.preview.close();
                        }}>
                        preview
                      </button>
                    ) : null}
                    <Image
                      image={
                        item.content.sys.contentType.sys.id === "product" ||
                        item.content.sys.contentType.sys.id === "bundlePage"
                          ? item.content.collectionImage
                          : item.content.image
                      }
                      mobileImage={
                        item.content.sys.contentType.sys.id === "product" ||
                        item.content.sys.contentType.sys.id === "bundlePage"
                          ? item.content.mobileCollectionImage
                          : item.content.mobileImage
                      }
                      alt={
                        item.content.sys.contentType.sys.id === "product" ||
                        item.content.sys.contentType.sys.id === "bundlePage"
                          ? item.content.collectionImage &&
                            item.content.collectionImage.description
                          : item.content.image && item.content.image.description
                      }
                      className="subnav__visual-image"
                      legacy={isPreview}
                      loading="lazy"
                    />
                    <p className="subnav__visual-text">{item.title}</p>
                  </VisualNavLinkTag>
                );
              })
            : null}
          <div className="subnav__separator" />
          <div className="subnav__categories">
            <div className="subnav__category">
              {categoryMenu1 &&
                categoryMenu1.items.map((cat) => (
                  <BuffyMenu
                    key={cat.id}
                    ulClass="shared__menu subnav__category-menu"
                    liClass="subnav__category-menu-item"
                    showMenuTitle
                    menuTitleClass="subnav__category-heading"
                    meuTitleTag="h3"
                    onClick={handleMobileMenu}
                    menu={cat}
                    extras={extras}
                  />
                ))}
            </div>
            <div className="subnav__category">
              {categoryMenu2 &&
                categoryMenu2.items.map((cat) => (
                  <BuffyMenu
                    key={cat.id}
                    ulClass="shared__menu subnav__category-menu"
                    liClass="subnav__category-menu-item"
                    showMenuTitle
                    menuTitleClass="subnav__category-heading"
                    meuTitleTag="h3"
                    onClick={handleMobileMenu}
                    menu={cat}
                    extras={extras}
                  />
                ))}
            </div>
          </div>
          <p className="subnav__shop-all">
            <ShopAllLinkTag
              href={shopAllRoute}
              onClick={handleMobileMenu}
              onKeyPress={handleMobileMenu}>
              {mainMenu && mainMenu.items[0].title}
            </ShopAllLinkTag>
          </p>
          {utilMenu ? (
            <BuffyMenu
              ulClass="shared__menu subnav__util-menu"
              liClass="subnav__menu-item"
              ignoreHide
              menu={utilMenu}
              onClick={handleMobileMenu}
              extras={extras}
            />
          ) : null}
        </div>
      </LatticeGrid>
    </>
  );
}

Header.designSystemProps = {
  menus: {
    type: "array",
    description: "List of menu objects.",
    propType: PropTypes.arrayOf(
      PropTypes.shape({
        node: PropTypes.shape({
          id: PropTypes.string.isRequired,
          title: PropTypes.string.isRequired,
          hiddenOnDevice: PropTypes.arrayOf(PropTypes.string),
        }),
      }),
    ).isRequired,
    subProps: {
      node: {
        type: "object",
        description: "Menu object.",
        subProps: {
          id: {
            type: "string",
            description: "Menu identifier.",
            required: true,
          },
          title: {
            type: "string",
            description: "Menu title.",
            required: true,
          },
          hiddenOnDevice: {
            type: "array",
            description: "Config list for hiding menu on mobile or desktop.",
            required: false,
          },
        },
        required: true,
      },
    },
    required: true,
  },
  transparent: {
    type: "boolean",
    description: "Transparent header background if true.",
    propType: PropTypes.bool,
    default: false,
    required: false,
  },
  color: {
    type: "string",
    description: `Color mode of header ("dark" | "light")`,
    propType: PropTypes.string,
    default: "dark",
    required: false,
  },
  layout: {
    type: "string",
    description: `Page layout ("home" | "page" | "product" | "collection" | "product-listing" | "bundle")`,
    propType: PropTypes.string.isRequired,
    required: true,
  },
  reviews: {
    type: "object",
    description: `Reviews object for "product" layout`,
    propType: PropTypes.objectOf(PropTypes.any),
    default: null,
    required: false,
  },
  announcement: {
    type: "object",
    description: "Accouncement media block object",
    propType: PropTypes.shape({
      id: PropTypes.string,
      type: PropTypes.string,
    }),
    subProps: {
      id: {
        type: "string",
        description: "Annoucement identifier.",
        required: true,
      },
      type: {
        type: "string",
        description: `Anncouncement type ("pencilbar")`,
        required: true,
      },
    },
    default: null,
    required: false,
  },
  discounts: {
    type: "array",
    description: "List of active site discounts.",
    propType: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
    default: null,
    required: false,
  },
  defaultDiscount: {
    type: "object",
    description: "Default discount object.",
    propType: PropTypes.objectOf(PropTypes.any),
    default: null,
    required: false,
  },
  cart: {
    type: "string",
    description: "Displays shopping cart if true.",
    propType: PropTypes.bool,
    default: true,
    required: false,
  },
  extras: {
    type: "object",
    description: "Additional settings for header features.",
    propType: PropTypes.objectOf(PropTypes.any).isRequired,
    subProps: {
      storeContext: {
        type: "context",
        description: "Global store context provider.",
        required: true,
      },
      alternativeContent: {
        type: "array",
        description: "List of alt content.",
        required: false,
      },
      sitewideAlternativeContent: {
        type: "array",
        description: "List of sitewide alt content",
        required: false,
      },
    },
    required: true,
  },
};

const propTypes = {};
const defaultProps = {};
Object.entries(Header.designSystemProps).map(([k, v]) => {
  if (v.propType) {
    propTypes[k] = v.propType;
  }
  if (v.default || typeof v.default !== "undefined") {
    defaultProps[k] = v.default;
  }
  return false;
});
Header.propTypes = { ...propTypes };
Header.defaultProps = { ...defaultProps };

Header.displayName = "Header";

export default Header;
