import React, { useState, useRef } from "react";
// import Client from "shopify-buy";
import PropTypes from "prop-types";

import Context from "../context/storeContext";

function ContextProvider({ children }) {
  const initialStoreState = {
    oosSuccess: null,
    products: [],
    shop: {
      currentProduct: null,
      currentVariant: null,
      currentBundle: null,
      currentQuantity: null,
    },
    productLoaded: false,
    currentProduct: null,
    currentVariant: null,
    currentQuantity: null,
    showOOS: false,
    showPreLaunch: false,
    showDiscount: false,
    params: {},
  };

  const [store, updateStore] = useState(initialStoreState);
  const productCta = useRef();

  return (
    <Context.Provider
      value={{
        store,
        productCta,
        initializePDP: () => {
          updateStore((prevState) => ({
            ...prevState,
            currentProduct: null,
            currentVariant: null,
          }));
        },
        updateShop: (shop, append) => {
          if (append) {
            updateStore((prevState) => ({
              ...prevState,
              shop: { ...prevState.shop, ...shop },
            }));
          } else {
            updateStore((prevState) => ({
              ...prevState,
              shop,
            }));
          }
        },
        updateCurrentProduct: (product) => {
          updateStore((prevState) => ({
            ...prevState,
            currentProduct: product,
          }));
        },
        updateCurrentVariant: (active) => {
          updateStore((prevState) => ({
            ...prevState,
            currentVariant: active,
          }));
        },
        updateCurrentBundle: (bundle) => {
          updateStore((prevState) => ({
            ...prevState,
            currentBundle: bundle,
          }));
        },
        updateCurrentQuantity: (quantity) => {
          updateStore((prevState) => ({
            ...prevState,
            currentQuantity: quantity,
          }));
        },
        updateShowOOS: (show) => {
          updateStore((prevState) => ({
            ...prevState,
            showOOS: show,
          }));
        },
        updateShowPreLaunch: (show) => {
          updateStore((prevState) => ({
            ...prevState,
            showPreLaunch: show,
          }));
        },
        updateParams: (params) => {
          updateStore((prevState) => ({
            ...prevState,
            params,
          }));
        },
        updateProductLoaded: (productLoaded) => {
          updateStore((prevState) => ({
            ...prevState,
            productLoaded,
          }));
        },
        updateShowDiscount: (show) => {
          updateStore((prevState) => ({
            ...prevState,
            showDiscount: show,
          }));
        },
        updateOosSuccess: (success) => {
          updateStore((prevState) => ({
            ...prevState,
            oosSuccess: success,
          }));
        },
      }}>
      {children}
    </Context.Provider>
  );
}

ContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ContextProvider;
