import { useState, useCallback, useRef } from "react";

export default function useHover(options) {
  const { mouseEnterDelayMS, mouseLeaveDelayMS } = options || {
    mouseEnterDelayMS: 0,
    mouseLeaveDelayMS: 0,
  };
  const [value, setValue] = useState(false);

  let mouseOverTimer = null;
  let mouseOutTimer = null;
  // Wrap in useCallback so we can use in dependencies below
  const handleMouseOver = useCallback(() => {
    clearTimeout(mouseOverTimer);
    mouseOverTimer = setTimeout(() => setValue(true), mouseEnterDelayMS);
    setValue(true);
  }, []);
  const handleMouseOut = useCallback(() => {
    clearTimeout(mouseOutTimer);
    mouseOutTimer = setTimeout(() => setValue(false), mouseLeaveDelayMS);
  }, []);

  // Keep track of the last node passed to callbackRef
  // so we can remove its event listeners.
  const ref = useRef();

  // Use a callback ref instead of useEffect so that event listeners
  // get changed in the case that the returned ref gets added to
  // a different element later. With useEffect, changes to ref.current
  // wouldn't cause a rerender and thus the effect would run again.
  const callbackRef = useCallback(
    (node) => {
      if (ref.current) {
        ref.current.removeEventListener("mouseenter", handleMouseOver);
        ref.current.removeEventListener("mouseleave", handleMouseOut);
      }

      ref.current = node;

      if (ref.current) {
        ref.current.addEventListener("mouseenter", handleMouseOver);
        ref.current.addEventListener("mouseleave", handleMouseOut);
      }
    },
    [handleMouseOver, handleMouseOut],
  );

  return [callbackRef, ref.current, value];
}
