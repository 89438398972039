// Quilt Utils
import {
  getAltContent,
  getAltFunction,
  getRedirectContent,
  getMenu,
  validDiscount,
  caseInsensitiveEqual,
} from "./index";

// Utils
import CONSTANTS from "../constants";

const isBrowser = typeof window !== "undefined";

const unTrunc = ({ page, alternativeContent }) =>
  (alternativeContent.mediaSections || []).map((acms) => {
    if (acms.where) {
      let obj = page[acms.where.type][acms.where.index];
      if (acms.where.subType && acms.where.subIndex !== null) {
        obj = obj[acms.where.subType][acms.where.subIndex];
      }
      return obj;
    }
    return acms;
  });

const getFunctions = ({ info, params, functions }) => {
  const altFunctions = [...(functions || {})];
  if (
    !altFunctions.find(
      (af) =>
        af.urlParamKey === info.urlParamKey &&
        af.urlParamValue === info.urlParamValue,
    ) &&
    info.classNames
  ) {
    altFunctions.push({
      ...info,
      CLASSNAMES: info.classNames,
    });
  }

  const hasInfo =
    (info.googleOptimizeExperimentId &&
      (info.googleOptimizeExperimentVariant ||
        info.googleOptimizeExperimentVariantList)) ||
    (info.urlParamKey && info.urlParamValue);
  const optimizeVariantMatch = Object.entries(params).find(
    ([k]) =>
      k === `${CONSTANTS.OPTIMIZE_PREFIX}${info.googleOptimizeExperimentId}`,
  );
  const optimizeVariant = optimizeVariantMatch && optimizeVariantMatch[1];
  const altFunctionMatch =
    hasInfo &&
    altFunctions.find((af) => {
      const variantListMatch =
        info.googleOptimizeExperimentId === af.googleOptimizeExperimentId &&
        ((af.googleOptimizeExperimentVariantList &&
          info.googleOptimizeExperimentVariantList &&
          optimizeVariant >= 0 &&
          af.googleOptimizeExperimentVariantList.includes(
            optimizeVariant.toString(),
          ) &&
          info.googleOptimizeExperimentVariantList.find((goevl) =>
            af.googleOptimizeExperimentVariantList.includes(goevl),
          )) ||
          (af.googleOptimizeExperimentVariantList &&
            af.googleOptimizeExperimentVariantList.includes(
              info.googleOptimizeExperimentVariant.toString(),
            )) ||
          (info.googleOptimizeExperimentVariantList &&
            optimizeVariant >= 0 &&
            af.googleOptimizeExperimentVariant === optimizeVariant &&
            info.googleOptimizeExperimentVariantList.includes(
              af.googleOptimizeExperimentVariant.toString(),
            )));
      const urlParamListMatch =
        info.urlParamKey &&
        af.urlParamList &&
        af.urlParamList.find(
          (upl) =>
            upl.key === info.urlParamKey && upl.value === info.urlParamValue,
        );
      return (
        (((!info.googleOptimizeExperimentId ||
          af.googleOptimizeExperimentId === info.googleOptimizeExperimentId) &&
          (!info.googleOptimizeExperimentVariant ||
            af.googleOptimizeExperimentVariant ===
              info.googleOptimizeExperimentVariant)) ||
          variantListMatch) &&
        (((!info.urlParamKey || af.urlParamKey === info.urlParamKey) &&
          (!info.urlParamValue || af.urlParamValue === info.urlParamValue)) ||
          urlParamListMatch)
      );
    });

  return altFunctionMatch;
};

export const getFooterInfo = ({
  params,
  sitewideAlternativeContent,
  menus,
  extras,
}) => {
  const creditsMenu = getMenu(menus, "Footer Credits");
  const shopAllMenu = getMenu(menus, "Footer Shop All");
  const aboutUsDesktopMenu = getMenu(menus, "Footer About Us (Desktop)");
  const aboutUsMobileMenu = getMenu(menus, "Footer About Us (Mobile)");
  const contactUsMenu = getMenu(menus, "Footer Contact Us");

  const creditsMenuMatch = getAltContent({
    params,
    content: creditsMenu && creditsMenu.alternativeContent,
    type: "menu",
    extras: {
      OPTIMIZE_PREFIX: extras && extras.OPTIMIZE_PREFIX,
    },
  });

  const shopAllMenuMatch = getAltContent({
    params,
    content: shopAllMenu && shopAllMenu.alternativeContent,
    type: "menu",
    extras: {
      OPTIMIZE_PREFIX: extras && extras.OPTIMIZE_PREFIX,
    },
  });

  const aboutUsDesktopMenuMatch = getAltContent({
    params,
    content: aboutUsDesktopMenu && aboutUsDesktopMenu.alternativeContent,
    type: "menu",
    extras: {
      OPTIMIZE_PREFIX: extras && extras.OPTIMIZE_PREFIX,
    },
  });

  const aboutUsMobileMenuMatch = getAltContent({
    params,
    content: aboutUsMobileMenu && aboutUsMobileMenu.alternativeContent,
    type: "menu",
    extras: {
      OPTIMIZE_PREFIX: extras && extras.OPTIMIZE_PREFIX,
    },
  });

  const contactUsMenuMatch = getAltContent({
    params,
    content: contactUsMenu && contactUsMenu.alternativeContent,
    type: "menu",
    extras: {
      OPTIMIZE_PREFIX: extras && extras.OPTIMIZE_PREFIX,
    },
  });

  const altFunctionSitewideMatch = getAltFunction({
    params,
    content: sitewideAlternativeContent,
    extras: {
      OPTIMIZE_PREFIX: CONSTANTS.OPTIMIZE_PREFIX,
    },
  });

  const anyHeaderMatch =
    creditsMenuMatch ||
    shopAllMenuMatch ||
    aboutUsDesktopMenuMatch ||
    aboutUsMobileMenuMatch ||
    contactUsMenuMatch ||
    altFunctionSitewideMatch;

  const footerInfo = {
    creditsMenu: creditsMenuMatch || creditsMenu,
    shopAllMenu: shopAllMenuMatch || shopAllMenu,
    aboutUsDesktopMenu: aboutUsDesktopMenuMatch || aboutUsDesktopMenu,
    aboutUsMobileMenu: aboutUsMobileMenuMatch || aboutUsMobileMenu,
    contactUsMenu: contactUsMenuMatch || contactUsMenu,
  };

  if (anyHeaderMatch) {
    // Get alt functions
    footerInfo.functions = getFunctions({
      info: anyHeaderMatch,
      params,
      functions: extras && extras.functions,
    });
  }

  return {
    ...footerInfo,
    classNames: footerInfo.functions && footerInfo.functions.CLASSNAMES,
    constants: footerInfo.functions && footerInfo.functions.CONSTANTS,
  };
};

export const getHeaderInfo = ({
  params,
  alternativeContent,
  sitewideAlternativeContent,
  menus,
  announcement,
  discounts,
  extras,
}) => {
  const mainMenu = getMenu(menus, "Main Menu");
  const utilMenu = getMenu(menus, "Utility Menu");
  const visualMenu = getMenu(menus, "Visual Menu");
  const categoryMenu1 = getMenu(menus, "Category Menu 1");
  const categoryMenu2 = getMenu(menus, "Category Menu 2");

  const mainMenuMatch = getAltContent({
    params,
    content: mainMenu && mainMenu.alternativeContent,
    type: "menu",
    extras: {
      OPTIMIZE_PREFIX: extras && extras.OPTIMIZE_PREFIX,
    },
  });

  const utilMenuMatch = getAltContent({
    params,
    content: utilMenu && utilMenu.alternativeContent,
    type: "menu",
    extras: {
      OPTIMIZE_PREFIX: extras && extras.OPTIMIZE_PREFIX,
    },
  });

  const visualMenuMatch = getAltContent({
    params,
    content: visualMenu && visualMenu.alternativeContent,
    type: "menu",
    extras: {
      OPTIMIZE_PREFIX: extras && extras.OPTIMIZE_PREFIX,
    },
  });

  const categoryMenu1Match = getAltContent({
    params,
    content: categoryMenu1 && categoryMenu1.alternativeContent,
    type: "menu",
    extras: {
      OPTIMIZE_PREFIX: extras && extras.OPTIMIZE_PREFIX,
    },
  });

  const categoryMenu2Match = getAltContent({
    params,
    content: categoryMenu2 && categoryMenu2.alternativeContent,
    type: "menu",
    extras: {
      OPTIMIZE_PREFIX: extras && extras.OPTIMIZE_PREFIX,
    },
  });

  const headerInfoMatch = getAltContent({
    params,
    content: alternativeContent,
    type: "header",
    extras: {
      OPTIMIZE_PREFIX: extras && extras.OPTIMIZE_PREFIX,
    },
  });

  const altFunctionSitewideMatch = getAltFunction({
    params,
    content: sitewideAlternativeContent,
    extras: {
      OPTIMIZE_PREFIX: CONSTANTS.OPTIMIZE_PREFIX,
    },
  });

  const extrasShowDiscounts =
    extras &&
    extras.SHOW_DISCOUNTS &&
    extras.SHOW_DISCOUNTS.toLowerCase() === "true";
  const showDiscounts =
    extrasShowDiscounts === null || extrasShowDiscounts === undefined
      ? process.env.SHOW_DISCOUNTS &&
        process.env.SHOW_DISCOUNTS.toLowerCase() === "true"
      : extrasShowDiscounts;

  const discountUpdate =
    announcement &&
    showDiscounts &&
    discounts &&
    discounts.find(
      (d) =>
        d.node.shopifyDiscount &&
        d.node.shopifyDiscount.title === params.discount,
    );
  const discountAnnouncementUpdate =
    discountUpdate && discountUpdate.node.announcement
      ? {
          ...discountUpdate.node.announcement,
          ctaColor: discountUpdate.node.badgeColor,
          displayTitle: discountUpdate.node.marketingTitle,
          discount: true,
        }
      : null;

  const anyHeaderMatch =
    mainMenuMatch ||
    utilMenuMatch ||
    visualMenuMatch ||
    categoryMenu1Match ||
    categoryMenu2Match ||
    headerInfoMatch ||
    discountAnnouncementUpdate ||
    altFunctionSitewideMatch;

  const headerInfo = {
    mainMenu: mainMenuMatch || mainMenu,
    utilMenu: utilMenuMatch || utilMenu,
    visualMenu: visualMenuMatch || visualMenu,
    categoryMenu1: categoryMenu1Match || categoryMenu1,
    categoryMenu2: categoryMenu2Match || categoryMenu2,
    header: headerInfoMatch,
    announcement: discountAnnouncementUpdate,
  };

  if (anyHeaderMatch) {
    // Get alt functions
    headerInfo.functions = getFunctions({
      info: anyHeaderMatch,
      params,
      functions: extras && extras.functions,
    });
  }

  return {
    ...headerInfo,
    classNames: headerInfo.functions && headerInfo.functions.CLASSNAMES,
    constants: headerInfo.functions && headerInfo.functions.CONSTANTS,
  };
};

export const getPageInfo = ({
  params,
  alternativeContent,
  sitewideAlternativeContent,
  page,
  functions,
}) => {
  const altMatch = getAltContent({
    params,
    type: "redirect",
    content: alternativeContent,
    extras: {
      OPTIMIZE_PREFIX: CONSTANTS.OPTIMIZE_PREFIX,
    },
  });
  // Check for any sitewide or page-level alt content functionality
  const altFunctionSitewideMatch = getAltFunction({
    params,
    content: sitewideAlternativeContent,
    extras: {
      OPTIMIZE_PREFIX: CONSTANTS.OPTIMIZE_PREFIX,
    },
  });
  const altFunctionPageMatch = getAltFunction({
    params,
    content: alternativeContent,
    extras: {
      OPTIMIZE_PREFIX: CONSTANTS.OPTIMIZE_PREFIX,
    },
  });

  const pageInfo = {
    page: {
      ...page,
      alternativeContent: null,
    },
  };

  if (altMatch) {
    pageInfo.page.mediaSections = unTrunc({
      page,
      alternativeContent: altMatch,
    });
    pageInfo.classNames = altMatch.classNames;
    pageInfo.redirect = altMatch.redirect;
  }

  if (altFunctionSitewideMatch || altFunctionPageMatch) {
    // Get alt functions
    pageInfo.functions = getFunctions({
      info: altFunctionSitewideMatch || altFunctionPageMatch,
      params,
      functions,
    });
  }
  return {
    ...pageInfo,
    classNames: pageInfo.functions && pageInfo.functions.CLASSNAMES,
    constants: pageInfo.functions && pageInfo.functions.CONSTANTS,
  };
};

const matchABTestAltContent = ({ alternativeContent, params, query }) => {
  const updateParams = { ...params };
  const acMatch = [];

  // For test purposes only
  const mockId = { "-41UuMTqTXGAA9Ia5Y33tA": 1 };
  (alternativeContent || []).map((c) => {
    if (
      isBrowser &&
      c.googleOptimizeExperimentId &&
      (c.googleOptimizeExperimentVariant ||
        c.googleOptimizeExperimentVariantList) &&
      window.dataLayer &&
      window.google_optimize !== undefined
    ) {
      const optimizeVariant = parseInt(
        query.mock === CONSTANTS.OPTIMIZE_MOCK_TOKEN
          ? mockId[c.googleOptimizeExperimentId]
          : window.google_optimize.get(c.googleOptimizeExperimentId),
        10,
      );
      if (
        optimizeVariant === c.googleOptimizeExperimentVariant ||
        (c.googleOptimizeExperimentVariantList &&
          c.googleOptimizeExperimentVariantList.includes(
            optimizeVariant.toString(),
          ))
      ) {
        updateParams[
          `${CONSTANTS.OPTIMIZE_PREFIX}${c.googleOptimizeExperimentId}`
        ] = optimizeVariant;
        acMatch.push(c);
      }
    }
    return true;
  });

  return {
    acMatch,
    params: updateParams,
  };
};

const matchUrlParamAltContent = ({ alternativeContent, query, params }) => {
  const updateParams = { ...params };
  const acMatch = [];
  (alternativeContent || []).map((c) => {
    if (
      c.urlParamKey &&
      c.urlParamValue &&
      isBrowser &&
      (query[c.urlParamKey] === c.urlParamValue ||
        window.localStorage.getItem(
          `${CONSTANTS.LOCAL_STORAGE_PREFIX}${c.urlParamKey}`,
        ) === c.urlParamValue)
    ) {
      updateParams[c.urlParamKey] = c.urlParamValue;
      acMatch.push(c);
      // Add url param to local storage to persist
      if (
        window.localStorage.getItem(
          `${CONSTANTS.LOCAL_STORAGE_PREFIX}${c.urlParamKey}`,
        ) !== c.urlParamValue
      ) {
        window.localStorage.setItem(
          `${CONSTANTS.LOCAL_STORAGE_PREFIX}${c.urlParamKey}`,
          c.urlParamValue,
        );
      }
    }
    return true;
  });

  return {
    acMatch,
    params: updateParams,
  };
};

const altContentToParams = ({
  query,
  params,
  alternativeContent,
  optimize,
}) => {
  let paramsCopy = { ...params };
  const { acMatch, params: newParams } = optimize
    ? matchABTestAltContent({
        alternativeContent,
        params,
        query,
      })
    : matchUrlParamAltContent({
        alternativeContent,
        query,
        params,
      });
  paramsCopy = newParams;

  // Make sure alt content redirect params get included
  acMatch.map((ac) => {
    const rc = getRedirectContent(ac);
    paramsCopy = rc && rc.params ? { ...paramsCopy, ...rc.params } : paramsCopy;
    return true;
  });

  return paramsCopy;
};

export const getParams = ({
  query,
  sitewideAlternativeContent,
  alternativeContent,
  menus,
  discounts,
  defaultDiscount,
  breadcrumb,
  productOptions,
  optimize,
}) => {
  let params = {
    // Don't forget the default discount!
    ...(defaultDiscount &&
    defaultDiscount.shopifyDiscount &&
    validDiscount(defaultDiscount.shopifyDiscount)
      ? { discount: defaultDiscount.shopifyDiscount.title }
      : undefined),
  };

  // Handle breadcrumb params
  if (isBrowser && !optimize) {
    if (breadcrumb) {
      window.localStorage.setItem(
        `${CONSTANTS.LOCAL_STORAGE_PREFIX}breadcrumb`,
        breadcrumb,
      );
    } else {
      const currBreadcrumb = window.localStorage.getItem(
        `${CONSTANTS.LOCAL_STORAGE_PREFIX}${CONSTANTS.BREADCRUMB_PARAM}`,
      );
      if (currBreadcrumb) {
        params[`${CONSTANTS.BREADCRUMB_PARAM}`] = currBreadcrumb;
      }
    }
  }

  // Handle product options
  if (isBrowser && productOptions && !optimize) {
    params = { ...params, ...productOptions };
  }

  // Handle reset param, which will remove existing storage
  // Auto function to copy legacy storage items to new "buffy_" prefix
  if (isBrowser) {
    CONSTANTS.LEGACY_STORAGE_VALUES.map((lsv) => {
      const value = window.localStorage.getItem(lsv);
      if (value) {
        window.localStorage.setItem(
          `${CONSTANTS.LOCAL_STORAGE_PREFIX}${lsv}`,
          value,
        );
        window.localStorage.removeItem(lsv);
      }
      return false;
    });
  }

  if (typeof query.reset !== "undefined" && isBrowser) {
    // Reset all local storage params
    Object.keys(window.localStorage).map((k) => {
      if (k.startsWith(CONSTANTS.LOCAL_STORAGE_PREFIX)) {
        window.localStorage.removeItem(k);
      }
      return false;
    });
  }

  // Discount url params
  const discountParamName = `${CONSTANTS.LOCAL_STORAGE_PREFIX}discount`;
  Object.entries(query).map(([k, v]) => {
    if (v === null && isBrowser) {
      window.localStorage.removeItem(`${CONSTANTS.LOCAL_STORAGE_PREFIX}${k}`);
    } else if (v && isBrowser) {
      // Check that this discount code exists before updating
      const discountMatch = (discounts || []).find(
        (d) =>
          d.node.shopifyDiscount &&
          validDiscount(d.node.shopifyDiscount) &&
          caseInsensitiveEqual(v, d.node.shopifyDiscount.title),
      );
      const localItem = window.localStorage.getItem(
        `${CONSTANTS.LOCAL_STORAGE_PREFIX}${k}`,
      );
      if (localItem !== v && discountMatch) {
        window.localStorage.setItem(`${CONSTANTS.LOCAL_STORAGE_PREFIX}${k}`, v);
      }
    }
    return true;
  });

  // Handle default discount
  // Check that stored discount is valid to not replace with default discount
  const storedDiscount =
    isBrowser && window.localStorage.getItem(discountParamName);
  const storedDiscountValid = storedDiscount
    ? (discounts || []).find(
        (d) =>
          d.node.shopifyDiscount &&
          caseInsensitiveEqual(storedDiscount, d.node.shopifyDiscount.title) &&
          validDiscount(d.node.shopifyDiscount),
      )
    : null;
  if (
    defaultDiscount &&
    isBrowser &&
    validDiscount(defaultDiscount.shopifyDiscount) &&
    !storedDiscountValid
  ) {
    window.localStorage.setItem(
      discountParamName,
      defaultDiscount.shopifyDiscount.title,
    );
  }

  // Find all discounts and match to any url params
  if (discounts) {
    discounts.map((d) => {
      if (
        isBrowser &&
        ((validDiscount(d.node.shopifyDiscount) &&
          caseInsensitiveEqual(query.discount, d.node.shopifyDiscount.title)) ||
          (validDiscount(d.node.shopifyDiscount) &&
            caseInsensitiveEqual(
              window.localStorage.getItem(discountParamName),
              d.node.shopifyDiscount.title,
            )))
      ) {
        // const updateParam = {};
        params.discount = d.node.shopifyDiscount.title;
        // Add url param to local storage to persist
        if (
          !caseInsensitiveEqual(
            window.localStorage.getItem(discountParamName),
            d.node.shopifyDiscount.title,
          )
        ) {
          window.localStorage.setItem(
            discountParamName,
            d.node.shopifyDiscount.title,
          );
        }
      }
      return true;
    });
  }

  // Handle alt content url params
  Object.entries(query).map(([k, v]) => {
    if (v === null && isBrowser) {
      window.localStorage.removeItem(`${CONSTANTS.LOCAL_STORAGE_PREFIX}${k}`);
    } else if (v && isBrowser) {
      const localItem = window.localStorage.getItem(
        `${CONSTANTS.LOCAL_STORAGE_PREFIX}${k}`,
      );
      // If this is a discount, make sure it's valid first
      const validOrNotDiscount =
        k === "discount"
          ? (discounts || []).find(
              (d) =>
                d.node.shopifyDiscount &&
                caseInsensitiveEqual(v, d.node.shopifyDiscount.title) &&
                validDiscount(d.node.shopifyDiscount),
            )
          : true;
      if (localItem !== v && validOrNotDiscount) {
        window.localStorage.setItem(`${CONSTANTS.LOCAL_STORAGE_PREFIX}${k}`, v);
      }
    }
    return true;
  });

  if (optimize) {
    // Find all alt content blocks and match to any url params or active optimize tests
    if (sitewideAlternativeContent) {
      params = altContentToParams({
        alternativeContent: sitewideAlternativeContent,
        query,
        params,
        optimize,
      });
    }
    if (alternativeContent) {
      params = altContentToParams({
        alternativeContent,
        query,
        params,
        optimize,
      });
    }
    menus.map((m) => {
      if (m.node.alternativeContent) {
        params = altContentToParams({
          alternativeContent: m.node.alternativeContent,
          query,
          params,
          optimize,
        });
      }
      return true;
    });
  } else {
    // Find all alt content blocks and match to any url params or active optimize tests
    if (sitewideAlternativeContent) {
      params = altContentToParams({
        alternativeContent: sitewideAlternativeContent,
        query,
        params,
      });
    }
    if (alternativeContent) {
      params = altContentToParams({
        alternativeContent,
        query,
        params,
      });
    }
    menus.map((m) => {
      if (m.node.alternativeContent) {
        params = altContentToParams({
          alternativeContent: m.node.alternativeContent,
          query,
          params,
        });
      }
      return true;
    });
  }

  return params;
};

export const getAvailableParams = ({
  sitewideAlternativeContent,
  alternativeContent,
  menus,
  discounts,
  StringParam,
}) => {
  // Build query params for sitewide content
  const queryParams = {};
  if (sitewideAlternativeContent) {
    sitewideAlternativeContent.map((ac) => {
      if (ac.urlParamKey && ac.urlParamValue) {
        queryParams[ac.urlParamKey] = StringParam;
      }
      return true;
    });
  }

  // Build query params for alternative content
  if (alternativeContent) {
    alternativeContent.map((ac) => {
      if (ac.urlParamKey && ac.urlParamValue) {
        queryParams[ac.urlParamKey] = StringParam;
      }
      return true;
    });
  }
  // Build query params for alternative menu
  menus.map((m) => {
    if (m.node.alternativeContent) {
      m.node.alternativeContent.map((ac) => {
        if (ac.urlParamKey && ac.urlParamValue) {
          queryParams[ac.urlParamKey] = StringParam;
        }
        return true;
      });
    }
    return true;
  });

  // Build query params for discounts
  if (discounts) {
    discounts.map((d) => {
      if (validDiscount(d.node.shopifyDiscount)) {
        queryParams.discount = StringParam;
      }
      return true;
    });
  }

  // Build query params for breadcrumbs
  queryParams.breadcrumb = StringParam;

  // Custom query params
  queryParams.reset = StringParam;
  queryParams.mock = StringParam;

  return queryParams;
};
