import React, { useState } from "react";
import PropTypes from "prop-types";

import Form from "./form";
import { corsFetch } from "../../utils";
import CONSTANTS from "../../constants";

function EmailSubscribe({
  className,
  id,
  forwardRef,
  title,
  list,
  label,
  formLabel,
  successMessage,
  messageColor,
  extras,
}) {
  const [email, setEmail] = useState("");
  const [formMessage, setFormMessage] = useState({
    error: false,
    message: "",
    // mode: "init",
  });

  const extrasDev = extras && extras.DEV && extras.DEV.toLowerCase() === "true";
  const isDev =
    extrasDev === null
      ? process.env.DEV && process.env.DEV.toLowerCase() === "true"
      : extrasDev;

  const emailSubClass = "shared__email-subscribe";
  let classList = [emailSubClass];
  classList = classList.concat(
    Array.isArray(className) ? className : className.split(" "),
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    const apiReturn = corsFetch({
      url: `https://a.klaviyo.com/api/v2/list/${list}/subscribe`,
      corsProxy: (extras && extras.CORS_PROXY) || CONSTANTS.CORS_PROXY,
      dev: isDev,
      data: {
        api_key:
          (extras && extras.KLAVIYO_API_KEY) || process.env.KLAVIYO_API_KEY,
        profiles: [
          {
            email,
          },
        ],
      },
    });

    apiReturn
      .then(() => {
        setFormMessage({
          error: false,
          message: successMessage,
          // mode: "show",
        });
      })
      .catch((error) => {
        let message = "";
        if (error.response) {
          message = error.response.data.detail || error.response.data.message;
          // console.log(error.response.data);
          // console.log(error.response.status);
          // console.log(error.response.headers);
        } else if (error.request) {
          // console.log(error.request);
        } else {
          message = error.message;
        }
        // console.log("error message", message);
        // console.log(error.config);
        setFormMessage({
          error: true,
          message: message || "Oops, there was an error. Try again.",
          // mode: "show",
        });
      });
  };

  let formStatus = "";
  if (formMessage.error) {
    formStatus = "error";
  } else if (formMessage.message) {
    formStatus = "success";
  }

  return (
    <div id={id} className={classList.join(" ")} ref={forwardRef}>
      <Form onSubmit={handleSubmit} label={formLabel}>
        <label className={`${emailSubClass}-label`} htmlFor={label}>
          {title ? <span>{title}</span> : null}
          <input
            type="email"
            name="email"
            placeholder="Enter Email"
            className={`${emailSubClass}-input`}
            id={label}
            onChange={(e) => setEmail(e.target.value)}
            value={email}
          />
        </label>
        <button type="submit" className={`${emailSubClass}-submit`}>
          <span>&#x2192;</span>
        </button>
        {formStatus === "error" ? (
          <p
            className={`${emailSubClass}-error ${
              messageColor ? `${emailSubClass}-msg-${messageColor}` : ""
            }`}>
            {formMessage.message}
          </p>
        ) : null}
        {formStatus === "success" ? (
          <p
            className={`${emailSubClass}-success ${
              messageColor ? `${emailSubClass}-msg-${messageColor}` : ""
            }`}>
            {formMessage.message}
          </p>
        ) : null}
      </Form>
    </div>
  );
}

EmailSubscribe.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  id: PropTypes.string,
  forwardRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any }),
  ]),
  title: PropTypes.string,
  list: PropTypes.string,
  label: PropTypes.string.isRequired,
  successMessage: PropTypes.string,
  formLabel: PropTypes.string,
  messageColor: PropTypes.string,
  extras: PropTypes.objectOf(PropTypes.any),
};

EmailSubscribe.defaultProps = {
  className: "",
  id: "",
  forwardRef: null,
  title: null,
  list: process.env.FOOTER_EMAIL_LIST,
  successMessage: "Thanks!",
  formLabel: "Email Subscribe",
  messageColor: null,
  extras: null,
};

EmailSubscribe.displayName = "EmailSubscribe";

export default EmailSubscribe;
