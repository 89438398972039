import React from "react";

const defaultContextValue = {
  data: {},
  set: () => {},
};

const ShopifyContext = React.createContext(defaultContextValue);

export default ShopifyContext;
