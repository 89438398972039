import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useQueryParams, StringParam } from "use-query-params";
// import { OutboundLink } from "gatsby-plugin-google-analytics";
import LatticeGrid from "lattice/lib/components/LatticeGrid";

import EmailSubscribe from "../molecules/email-subscribe";
// import Button from "../atoms/button";
// import Input from "../atoms/input";
import BuffyLogo from "../atoms/icons/buffy-logo";
import { getLink } from "../../utils";
import BuffyMenu from "../atoms/menu";
import CONSTANTS from "../../constants";

import {
  getParams,
  getAvailableParams,
  getFooterInfo,
} from "../../utils/page-info";

function Footer({ menus, email, extras }) {
  const queryParams = getAvailableParams({
    menus,
    sitewideAlternativeContent: extras && extras.sitewideAlternativeContent,
    StringParam,
  });
  const [query] = useQueryParams(queryParams);
  const [params] = useState(
    getParams({
      query,
      sitewideAlternativeContent: extras && extras.sitewideAlternativeContent,
      menus,
    }),
  );
  const footerInfo = getFooterInfo({
    params,
    sitewideAlternativeContent: extras && extras.sitewideAlternativeContent,
    menus,
    extras,
  });

  const [creditsMenu, setCreditsMenu] = useState(footerInfo.creditsMenu);
  const [shopAllMenu, setShopAllMenu] = useState(footerInfo.shopAllMenu);
  const [aboutUsDesktopMenu, setAboutUsDesktopMenu] = useState(
    footerInfo.aboutUsDesktopMenu,
  );
  const [aboutUsMobileMenu, setAboutUsMobileMenu] = useState(
    footerInfo.aboutUsMobileMenu,
  );
  const [contactUsMenu, setContactUsMenu] = useState(footerInfo.contactUsMenu);
  const [pageClasses, setPageClasses] = useState(footerInfo.classNames);
  const [optimizeLoaded, updateOptimizeLoaded] = useState(false);

  const runAltFunctions = (info) => {
    if (info.functions && info.functions.FUNC && info.functions.FUNC.footer) {
      info.functions.FUNC.footer();
    }
  };

  useEffect(() => {
    runAltFunctions(footerInfo);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      if (window.google_optimize && !optimizeLoaded) {
        clearInterval(interval);
        const optimizeParams = getParams({
          query,
          sitewideAlternativeContent:
            extras && extras.sitewideAlternativeContent,
          menus,
          optimize: true,
        });
        if (
          optimizeParams &&
          Object.keys(optimizeParams).find((k) =>
            k.includes(CONSTANTS.OPTIMIZE_PREFIX),
          )
        ) {
          const optimizeFooterInfo = getFooterInfo({
            params: optimizeParams,
            sitewideAlternativeContent:
              extras && extras.sitewideAlternativeContent,
            menus,
            extras,
          });

          setCreditsMenu(optimizeFooterInfo.creditsMenu);
          setShopAllMenu(optimizeFooterInfo.shopAllMenu);
          setAboutUsDesktopMenu(optimizeFooterInfo.aboutUsDesktopMenu);
          setAboutUsMobileMenu(optimizeFooterInfo.aboutUsMobileMenu);
          setContactUsMenu(optimizeFooterInfo.contactUsMenu);
          setPageClasses(optimizeFooterInfo.classNames);

          runAltFunctions(optimizeFooterInfo);
        }
        updateOptimizeLoaded(true);
      }
    }, 10);
    return () => clearInterval(interval);
  }, []);

  const footerLogoRoute = getLink({
    item: null,
    extras,
  });
  // const LinkTag = footerLogoRoute.startsWith("http") ? OutboundLink : "a";
  const LinkTag = "a";

  return (
    <LatticeGrid
      fullWidth
      innerGrid
      className={`footer ${pageClasses ? pageClasses.join(" ") : ""}`}
      id={extras && extras.id}
      tag="footer">
      <LinkTag href={footerLogoRoute} className="footer__logo">
        <BuffyLogo color="standardWhite" />
        Buffy
      </LinkTag>
      <div className="footer__menu">
        {shopAllMenu ? (
          <BuffyMenu
            ulClass="shared__menu linked-list__container"
            liClass="linked-list__list-item"
            liHeaderClass="linked-list__list-item-header"
            menu={shopAllMenu}
            extras={extras}
          />
        ) : null}
        {aboutUsDesktopMenu ? (
          <BuffyMenu
            ulClass="shared__menu linked-list__container linked-list__desktop"
            liClass="linked-list__list-item"
            liHeaderClass="linked-list__list-item-header"
            menu={aboutUsDesktopMenu}
            extras={extras}
          />
        ) : null}
        {aboutUsMobileMenu ? (
          <BuffyMenu
            ulClass="shared__menu linked-list__container linked-list__mobile"
            liClass="linked-list__list-item"
            liHeaderClass="linked-list__list-item-header"
            menu={aboutUsMobileMenu}
            extras={extras}
          />
        ) : null}
        {contactUsMenu ? (
          <BuffyMenu
            ulClass="shared__menu linked-list__container"
            liClass="linked-list__list-item"
            liHeaderClass="linked-list__list-item-header"
            menu={contactUsMenu}
            extras={extras}
          />
        ) : null}
      </div>
      {email ? (
        <EmailSubscribe
          id="footer__email_newsletter_submit"
          className="footer-subscribe"
          title={
            (footerInfo.constants && footerInfo.constants.DISCOUNT_COPY) ||
            CONSTANTS.DISCOUNT_COPY
          }
          label="klaviyo_email_input"
          successMessage="Here's your $10 off code: WELCOME10"
          formLabel="Buffy newsletter form"
          extras={extras}
        />
      ) : null}
      <div className="footer__sublinks">
        <span>
          &copy;
          {new Date().getFullYear()}
        </span>
        {creditsMenu ? (
          <BuffyMenu
            ulClass="shared__menu linked-list__container"
            liClass="linked-list__list-item"
            liHeaderClass="linked-list__list-item-header"
            menu={creditsMenu}
            extras={extras}
          />
        ) : null}
      </div>
    </LatticeGrid>
  );
}

Footer.designSystemProps = {
  menus: {
    type: "array",
    description: "List of menu objects.",
    propType: PropTypes.arrayOf(
      PropTypes.shape({
        node: PropTypes.shape({
          id: PropTypes.string,
          title: PropTypes.string,
          hideOnDevice: PropTypes.arrayOf(PropTypes.string),
          items: PropTypes.arrayOf(PropTypes.any),
        }).isRequired,
      }).isRequired,
    ).isRequired,
    subProps: {
      node: {
        type: "object",
        description: "Menu object.",
        subProps: {
          id: {
            type: "string",
            description: "Menu identifier.",
            required: false,
          },
          title: {
            type: "string",
            description: "Menu title.",
            required: false,
          },
          hiddenOnDevice: {
            type: "array",
            description: "Config list for hiding menu on mobile or desktop.",
            required: false,
          },
          items: {
            type: "array",
            description: "List of menu items.",
            required: false,
          },
        },
        required: true,
      },
    },
    required: true,
  },
  email: {
    type: "boolean",
    description: "Displays email subscribe form if true.",
    propType: PropTypes.bool,
    default: true,
    required: false,
  },
  extras: {
    type: "object",
    description: "Additional settings for footer features.",
    propType: PropTypes.objectOf(PropTypes.any),
    subProps: {
      storeContext: {
        type: "context",
        description: "Global store context provider.",
        required: false,
      },
    },
    default: null,
    required: false,
  },
};

const propTypes = {};
const defaultProps = {};
Object.entries(Footer.designSystemProps).map(([k, v]) => {
  if (v.propType) {
    propTypes[k] = v.propType;
  }
  if (v.default || typeof v.default !== "undefined") {
    defaultProps[k] = v.default;
  }
  return false;
});
Footer.propTypes = { ...propTypes };
Footer.defaultProps = { ...defaultProps };

Footer.displayName = "Footer";

export default Footer;
