const isE2E =
  process.env.E2E_TEST && process.env.E2E_TEST.toLowerCase() === "true";
const SHOPIFY_SHOP_NAME = isE2E
  ? process.env.E2E_SHOPIFY_SHOP_NAME
  : process.env.SHOPIFY_SHOP_NAME;

const CONSTANTS = {
  CURRENCY_SYMBOL: "$",
  CURRENCY: "USD",
  CORS_PROXY: "https://cors-anywhere.herokuapp.com/",
  TRY_NOW_BUTTON_TEXT: "Add to Cart",
  TRY_NOW_BUTTON_COLOR: "buffyOrange",
  DEFAULT_BADGE_COLOR: "purple",
  GIFT_CARD_BUTTON_TEXT: "Add to Cart",
  OOS_BUTTON_TEXT: "Notify Me When In Stock!",
  OOS_BUTTON_COLOR: "buffyPurple",
  PRE_LAUCH_BUTTON_TEXT: "Get Updates",
  UNAVAILABLE_BUTTON_TEXT: "Notify Me",
  DISCOUNT_BUTTON_TEXT: "Get Discount",
  PRELOAD_TEXT: "Loading Product...",
  BUNDLE_START_TEXT: "Start Building!",
  BUNDLE_NEXT_TEXT: "Confirm Selection",
  BUNDLE_REVIEW_TEXT: "Review Bundle",
  BUNDLE_CANCEL_TEXT: "Cancel",
  BUNDLE_ITEM_DEFAULT_MESSAGE: "Choose your product",
  BUNDLE_PRICE_NAV_TITLE: "Bundle total",
  UPSELL_ITEM_PREFIX: "Add",
  CART_TITLE: "Try before buying.",
  CART_TOTAL_TITLE: "Total",
  CART_SUBTOTAL_TITLE: "Subtotal",
  CART_PRICE_DISCOUNT_TITLE_PREFIX: "Full Price After",
  CART_PRICE_DISCOUNT_TITLE_SUFFIX: "Discount",
  CART_FULL_PRICE_TITLE: "Full Price",
  CART_CHECKOUT_TITLE: "Check Out",
  CART_EMPTY_TITLE: "Your cart is empty :(",
  CART_SHOP_TITLE: "Continue Shopping",
  CART_LOADING: "Loading Cart...",
  CART_BUTTON_COLOR: "buffyOrange",
  CART_URL: `https://${SHOPIFY_SHOP_NAME}.myshopify.com/cart`,
  SHOP_URL: `https://${SHOPIFY_SHOP_NAME}.myshopify.com`,
  DIR_MAP: {
    product: "products",
    collection: "collections",
    page: "page",
    productListing: "product-listing",
    bundlePage: "bundle",
  },
  DISCOUNT_CODE: "WELCOME10",
  OOS_QUANTITY: 0,
  DISCOUNT_COPY: "Get $10 off first order",
  PLP_DISCOUNT_FILTER_EMPTY: "There are no products on sale at this time.",
  PRE_LAUNCH_COPY:
    "This item hasn’t launched yet! You are seeing this because we wanted you to see it first! Sign-up for updates and we will let you know when it goes live!",
  GIFT_COPY: "Include a recipient and note (Optional)",
  GIFT_TERMS_COPY:
    "Gift card purchases are final sale. Promotions and discounts do not apply. You will receive your gift card within 24 hours.",
  GIFT_NOTE_MAX_LENGTH: 500,
  RESERVED_PARAMS: [
    "size",
    "color",
    "firmness",
    "quantity",
    "discount",
    "value",
    "variant",
    "reset",
    "breadcrumb",
  ],
  BREADCRUMB_PARAM: "breadcrumb",
  DEFAULT_BREADCRUMB_VALUE: "all",
  LOCAL_STORAGE_PREFIX: "buffy_",
  LEGACY_STORAGE_VALUES: ["hp", "discount", "menu", "block", "redirect"],
  PDP_PARAMS: ["size", "color", "firmness", "value"],
  OPTIMIZE_PREFIX: "optimize_",
  MOBILE_BREAKPOINT: 768,
  BUFFY_LOGO_URL:
    "https://images.ctfassets.net/9tqo559eytsj/425qMKFs7cPFpZByauMxV1/152eb566754aeee87f1c9bc8b04b6595/logo_small.png",
  AB_TEST_TIMEOUT: 800,
  AB_LOAD_TIMEOUT: 0,
  AB_OPTIMIZE_TIMEOUT: 300,
  PDP_SLIDER_SKELETON: false,
  ENABLE_CAROUSEL_LAZY_LOADING: true,
  CONTENTFUL_STORAGE_ENV: "storage",
  DEV_LOADING_STYLE_TIMEOUT: 2000,
  OPTIMIZE_MOCK_TOKEN: "e6WwhVeuWYM7QjaV",
};

export default CONSTANTS;
