import React from "react";
import Axios from "axios";
import { getSrc } from "gatsby-plugin-image";
import { gridDesktop, gridTablet } from "lattice/src/breakpoints.module.scss";
import atob from "atob";
import format from "date-fns/format";
import loadable from "@loadable/component";
import CONSTANTS from "../constants";

const NextArrow = loadable(() =>
  import(
    /* webpackChunkName: 'quilt-src-components-atoms-next-arrow' */ "../components/atoms/next-arrow"
  ),
);
const PrevArrow = loadable(() =>
  import(
    /* webpackChunkName: 'quilt-src-components-atoms-prev-arrow' */ "../components/atoms/prev-arrow"
  ),
);
const Image = loadable(() =>
  import(
    /* webpackChunkName: 'quilt-src-components-atoms-image' */ "../components/atoms/image"
  ),
);

const isE2E =
  process.env.E2E_TEST && process.env.E2E_TEST.toLowerCase() === "true";

export const decodeId = (id) => {
  let did = atob(id);
  did = did.substring(did.lastIndexOf("/") + 1, did.length);
  return did;
};

export const getMenu = (menus, search) => {
  const menu =
    menus &&
    menus.find(
      (m) =>
        m.node.title.includes(search) &&
        !m.node.title.includes(CONSTANTS.ALT_MENU_TEXT),
    );
  return menu && menu.node;
};

export const isExternalLink = (route) => route.startsWith("http");

export const getLink = ({ item, type, slug, extras }) => {
  if (item && item.url) {
    return item.url;
  }
  const { domain, DIR_MAP } = extras || {};
  const prefix = `${domain || ""}/`;
  let linkType =
    item &&
    item.content &&
    item.content.sys &&
    item.content.sys.contentType.sys.id
      ? `${
          (DIR_MAP || CONSTANTS.DIR_MAP)[item.content.sys.contentType.sys.id] ||
          item.content.sys.contentType.sys.id
        }/`
      : type || "";
  let linkSlug =
    item && item.content
      ? item.content.slug ||
        (item.content.shopifyProduct && item.content.shopifyProduct.handle)
      : slug || "";

  if (linkType === "page/") {
    linkType = "";
  }
  if (
    item &&
    item.itemData &&
    item.itemData.quantity &&
    linkSlug.includes("cloud-pillow")
  ) {
    linkSlug = `${linkSlug}?quantity=${item.itemData.quantity}`;
  }
  return `${prefix}${linkType}${linkSlug}`;
};

export const camelCaseToDash = (str) =>
  str.replace(/([a-z0-9]|(?=[A-Z]))([A-Z])/g, "$1-$2").toLowerCase();

export const getLinkProps = ({ item }) => {
  const exclude = ["quantity"];
  const props = {};
  if (item && item.itemData) {
    Object.entries(item.itemData).map(([k, v]) => {
      if (!exclude.includes(k)) {
        props[camelCaseToDash(k)] = v;
      }
      return false;
    });
    return props;
  }
  return null;
};

export const validDiscount = (discount) =>
  discount &&
  discount.status === "ACTIVE" &&
  (!discount.endsAt ||
    format(new Date(), "yyyy-MM-dd'T'HH:mm:ssxxx") <
      format(new Date(discount.endsAt), "yyyy-MM-dd'T'HH:mm:ssxxx")) &&
  (!discount.usageLimit || discount.asyncUsageCount < discount.usageLimit);

export const caseInsensitiveEqual = (a, b) =>
  a === b || (a && b && a.toLowerCase() === b.toLowerCase());

const mapUrlParams = (params) => {
  const mapParams = (params && { ...params }) || {};
  if (typeof window !== "undefined") {
    const urlSplit = window.location.search.split("?");
    if (urlSplit[1]) {
      urlSplit[1].split("&").map((param) => {
        const paramSplit = param.split("=");
        if (paramSplit[0]) {
          mapParams[paramSplit[0]] = paramSplit[1] || null;
        }
        return false;
      });
    }
  }
  return mapParams;
};

export const getRedirectContent = (alt) => {
  if (!alt || (!alt.discount && !alt.redirectUrl && !alt.redirectDestination)) {
    return null;
  }
  const redirects = {
    params: mapUrlParams(),
  };
  if (alt.discount && validDiscount(alt.discount.shopifyDiscount)) {
    redirects.params.discount = alt.discount.shopifyDiscount.title;
  }
  if (alt.redirectDestination) {
    if (alt.redirectDestination.sys.contentType.sys.id === "product") {
      redirects.dest = getLink({
        type: "products/",
        slug: alt.redirectDestination.shopifyProduct.handle,
      });
    } else if (alt.redirectDestination.sys.contentType.sys.id) {
      redirects.dest = getLink({
        type: `${
          CONSTANTS.DIR_MAP[alt.redirectDestination.sys.contentType.sys.id]
        }/`,
        slug: alt.redirectDestination.slug,
      });
    }
  }
  const urlSplit = (alt.redirectUrl && alt.redirectUrl.split("?")) || [];
  if (urlSplit[0]) {
    [redirects.dest] = urlSplit;
  }
  if (urlSplit[1]) {
    urlSplit[1].split("&").map((param) => {
      const paramSplit = param.split("=");
      if (paramSplit[0]) {
        redirects.params[paramSplit[0]] = paramSplit[1] || null;
      }
      return false;
    });
  }
  redirects.url = `${redirects.dest || ""}${
    Object.entries(redirects.params).length ? "?" : ""
  }${Object.entries(redirects.params)
    .map(([k, v]) => `${k}${v ? `=${encodeURIComponent(v)}` : ""}`)
    .join("&")}`;

  return redirects;
};

const getAltContentMatch = ({ params, content, extras }) => {
  let altMatch = null;
  const optimizePrefix = extras.OPTIMIZE_PREFIX || CONSTANTS.OPTIMIZE_PREFIX;
  // Check if alternative content is found in params
  const allParams = Object.entries(params);
  for (let i = 0; i < allParams.length; i += 1) {
    const [k, v] = allParams[i];
    altMatch = k.startsWith(optimizePrefix)
      ? content.find(
          (ac) =>
            `${optimizePrefix}${ac.googleOptimizeExperimentId}` === k &&
            (ac.googleOptimizeExperimentVariant === v ||
              (ac.googleOptimizeExperimentVariantList &&
                ac.googleOptimizeExperimentVariantList.includes(v.toString()))),
        )
      : content.find((ac) => ac.urlParamKey === k && ac.urlParamValue === v);
    if (altMatch) {
      break;
    }
  }
  return altMatch;
};

export const getAltFunction = ({ params, content, extras }) => {
  if (!content) {
    return null;
  }
  const altMatch = getAltContentMatch({ params, content, extras });

  return (
    altMatch && {
      googleOptimizeExperimentId: altMatch.googleOptimizeExperimentId,
      googleOptimizeExperimentVariant: altMatch.googleOptimizeExperimentVariant,
      googleOptimizeExperimentVariantList:
        altMatch.googleOptimizeExperimentVariantList,
      urlParamKey: altMatch.urlParamKey,
      urlParamValue: altMatch.urlParamValue,
      classNames: altMatch.classNames,
      id: altMatch.id,
    }
  );
};

export const getAltContent = ({ params, content, type, extras }) => {
  if (!content) {
    return null;
  }
  const altMatch = getAltContentMatch({ params, content, extras });
  if (type === "menu" && altMatch) {
    return {
      ...(altMatch.mediaSections && altMatch.mediaSections[0]),
      classNames: altMatch.classNames,
      urlParamKey: altMatch.urlParamKey,
      urlParamValue: altMatch.urlParamValue,
      id: altMatch.id,
    };
  }

  if (type === "header" && altMatch) {
    return {
      transparent: altMatch.transparentHeader,
      color: altMatch.headerMode,
      announcement: altMatch.announcement,
      classNames: altMatch.classNames,
      urlParamKey: altMatch.urlParamKey,
      urlParamValue: altMatch.urlParamValue,
      id: altMatch.id,
    };
  }

  if (type === "redirect" && altMatch) {
    const redirect = getRedirectContent(altMatch);
    return {
      mediaSections: altMatch.mediaSections,
      classNames: altMatch.classNames,
      redirect,
      urlParamKey: altMatch.urlParamKey,
      urlParamValue: altMatch.urlParamValue,
      id: altMatch.id,
    };
  }
  return altMatch && altMatch.mediaSections;
};

export const getMediaBlock = (blocks, search) => {
  const block = blocks.find((m) => m.node.title.includes(search));
  return block && block.node;
};

export const slugify = (string) => {
  const a =
    "àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;";
  const b =
    "aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------";
  const p = new RegExp(a.split("").join("|"), "g");

  return string
    .toString()
    .toLowerCase()
    .replace(/\s+/g, "-") // Replace spaces with -
    .replace(p, (c) => b.charAt(a.indexOf(c))) // Replace special characters
    .replace(/&/g, "-and-") // Replace & with 'and'
    .replace(/[^\w-]+/g, "") // Remove all non-word characters
    .replace(/--+/g, "-") // Replace multiple - with single -
    .replace(/^-+/, "") // Trim - from start of text
    .replace(/-+$/, ""); // Trim - from end of text
};

export const dashToCamelCase = (options) => {
  const str = options.str || "";
  const sep = options.sep || "";
  const capFirst = options.capFirst || false;
  let word = 0;
  return str.replace(/(-|^)([^-]?)/g, (_, prep, letter) => {
    const upperLetter = !word && !capFirst ? letter : letter.toUpperCase();
    word += 1;
    return (prep && sep) + upperLetter;
  });
};

export const stripHtml = (str) =>
  str && str.replace(/(<([^>]+)>)/gi, "").trim();

export const getFromPrice = (variants) => {
  let min = Math.trunc(variants[0].node.price.amount);

  for (let i = 1, len = variants.length; i < len; i += 1) {
    const v = Math.trunc(variants[i].node.price.amount);
    min = v < min ? v : min;
  }

  return min;
};

export const isMarkdown = (text, html) =>
  text.includes("\n") ||
  text.includes("&nbsp;") ||
  (html.match(/<\//g) || []).length > 1;

export const isGiftCardProduct = (product) =>
  product &&
  ((product.tags && product.tags.includes("gift-card")) ||
    (product.shopifyProduct &&
      product.shopifyProduct.tags.includes("gift-card")));

export const productHasNoInventory = (product) =>
  product &&
  ((product.tags && product.tags.includes("gift-card")) ||
    (product.shopifyProduct &&
      product.shopifyProduct.tags.includes("gift-card")));

export const getFirstAvailableVariant = (product) => {
  const defaultParams = {};
  if (product.productVariant) {
    // convert this variant to individual options
    const variantMatch = product.shopifyProduct.variants.edges.find(
      (v) => decodeId(v.node.id) === product.productVariant,
    );
    const variantSplit = variantMatch
      ? variantMatch.node.title.split(" / ")
      : null;
    let optionMatches = null;
    if (variantSplit) {
      // Match the variant title with an option name
      optionMatches = variantSplit.map((vs) => {
        const optionMatch = product.shopifyProduct.options.find((o) =>
          o.values.includes(vs),
        );
        return { ...optionMatch, title: vs };
      });
    }
    if (optionMatches) {
      for (let i = 0; i < optionMatches.length; i += 1) {
        defaultParams[optionMatches[i].name.toLowerCase()] =
          optionMatches[i].title;
      }
    }
  } else if (product.defaultOptions && product.defaultOptions.length) {
    product.defaultOptions.map((o) => {
      if (o.sys.contentType.sys.id === "productOption") {
        if (
          o.shopifyOption &&
          o.shopifyOption.productHandle === product.shopifyProduct.handle
        ) {
          defaultParams[o.shopifyOption.optionName.toLowerCase()] =
            o.shopifyOption.name;
        }
      } else if (o.sys.contentType.sys.id === "productColor") {
        if (
          o.shopifyColorOption &&
          o.shopifyColorOption.productHandle === product.shopifyProduct.handle
        ) {
          defaultParams.color = o.shopifyColorOption.color;
        }
      }
      return false;
    });
  }

  const firstVariant = product.shopifyProduct.variants.edges.find(
    (v) => v.node.availableForSale,
  );
  const firstDefaultVariant = product.shopifyProduct.variants.edges.find(
    (v) => {
      const optionList = v.node.title
        .split(" / ")
        .map((ol) => ol.toLowerCase());
      let hasOption = true;
      if (Object.keys(defaultParams).length) {
        hasOption =
          hasOption &&
          ((defaultParams.size &&
            optionList.includes(defaultParams.size.toLowerCase())) ||
            !defaultParams.size) &&
          ((defaultParams.color &&
            optionList.includes(defaultParams.color.toLowerCase())) ||
            !defaultParams.color) &&
          ((defaultParams.firmness &&
            optionList.includes(defaultParams.firmness.toLowerCase())) ||
            !defaultParams.firmness) &&
          ((defaultParams.value &&
            optionList.includes(defaultParams.value.toLowerCase())) ||
            !defaultParams.value);
      }
      hasOption =
        hasOption && (product.productVariant || v.node.availableForSale);
      return hasOption;
    },
  );
  const variant = firstDefaultVariant || firstVariant;
  return variant && variant.node;
};

export const isAvailable = (variant, oosQty, product) => {
  const noQty =
    !variant ||
    typeof variant.quantityAvailable === "undefined" ||
    variant.quantityAvailable === null ||
    productHasNoInventory(product);
  const validQty =
    variant.quantityAvailable > (oosQty || CONSTANTS.OOS_QUANTITY);
  return (
    isGiftCardProduct(product) ||
    (noQty && variant.availableForSale) ||
    (!validQty && variant.availableForSale) ||
    validQty
  );
};

export const mapVariantOptions = (product, variant) => {
  // Search the variant to build a mapping
  const optionsMap = {};
  const activeOpts = variant.title.split(" / ");
  activeOpts.map((ao) => {
    const optionMatch = product.shopifyProduct.options.find((o) =>
      o.values.includes(ao),
    );
    if (optionMatch) {
      optionsMap[optionMatch.name.toLowerCase()] = ao;
    }
    return true;
  });
  return optionsMap;
};

export const isOOS = ({
  option,
  activeVariant,
  options,
  variants,
  oosQty,
  noInventory,
}) => {
  if (!activeVariant || !activeVariant.title || noInventory) {
    return false;
  }
  // Given active variant title, look for variants that match variant options
  // NOT included in this option group
  const activeOpts = activeVariant.title.split(" / ");
  let spliceIndex = -1;
  options.values.map((v) => {
    const optIndex = activeOpts.indexOf(v);
    if (optIndex !== -1) {
      spliceIndex = optIndex;
      activeOpts.splice(optIndex, 1);
    }
    return true;
  });
  activeOpts.splice(spliceIndex, 0, option);
  const checkVariantTitle = activeOpts.join(" / ");

  const checkVariant = variants.find((v) => v.title === checkVariantTitle);
  return checkVariant && !isAvailable(checkVariant, oosQty);
};

const imageTypeFilter = (image) => image;
export const isStaticImage = (sources, mediaBlockClasses) => {
  const imgSrc = getSrc((sources && sources.desktop) || sources);
  return (
    imgSrc &&
    imgSrc.includes(".png") &&
    mediaBlockClasses &&
    mediaBlockClasses.includes(CONSTANTS.STATIC_IMAGE_CLASS)
  );
};

export const getCarouselSettings = (type, extras) => {
  if (type === "ksp") {
    return {
      settings: {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToScroll: 1,
        variableWidth: true,
        lazyLoad: "progressive",
        arrows: false,
        centerMode: true,
        initialSlide: 2,
        responsive: [
          {
            breakpoint: parseInt(gridDesktop, 10),
            settings: {
              dots: true,
            },
          },
        ],
        ...extras,
      },
    };
  }
  if (type === "trial") {
    return {
      settings: {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToScroll: 1,
        variableWidth: true,
        lazyLoad: "progressive",
        arrows: false,
        responsive: [
          {
            breakpoint: parseInt(gridTablet, 10),
            settings: {
              dots: true,
              lazyLoad: "ondemand",
            },
          },
        ],
        ...extras,
      },
    };
  }
  if (type === "collection" || type === "card") {
    return {
      fullWidth: false,
      settings: {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToScroll: 1,
        variableWidth: true,
        arrows: true,
        lazyLoad: "progressive",
        nextArrow: (
          <NextArrow label={(extras && extras.headline) || "Product"} />
        ),
        prevArrow: (
          <PrevArrow label={(extras && extras.headline) || "Product"} />
        ),
        responsive: [
          {
            breakpoint: parseInt(gridTablet, 10),
            settings: {
              lazyLoad: false,
            },
          },
        ],
        ...extras,
      },
    };
  }
  if (type === "review") {
    return {
      fullWidth: true,
      settings: {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToScroll: 1,
        variableWidth: true,
        arrows: true,
        lazyLoad: "progressive",
        nextArrow: <NextArrow label="Review" />,
        prevArrow: <PrevArrow label="Review" />,
        responsive: [
          {
            breakpoint: parseInt(gridTablet, 10),
            settings: {
              lazyLoad: false,
            },
          },
        ],
        ...extras,
      },
    };
  }
  if (type === "product-hero") {
    const images = extras && extras.images;
    const hideDots = extras && extras.mode === "panel";
    return {
      grid: false,
      settings: {
        infinite: true,
        speed: 500,
        slidesToScroll: 1,
        variableWidth: true,
        arrows: false,
        dots: !hideDots,
        lazyLoad: "progressive",
        dotsClass: `slick-dots slick-thumb ${
          images && images.length > 4 ? "slick-thumb--small" : ""
        }`,
        customPaging: (i) => {
          const imageSources =
            images[i].mobileImage && images[i].image
              ? {
                  desktop: images[i].image,
                  mobile: images[i].mobileImage,
                }
              : null;
          return (
            <button
              type="button"
              aria-label={images[i].image.description || "Product Thumbnail"}>
              {images[i].description || `Product Image ${i + 1}`}
              <Image
                image={imageSources || images[i].image}
                alt={images[i].image.description || "Product Thumbnail"}
                className="product-thumb"
                loading="eager"
                as="span"
                width={70}
              />
            </button>
          );
        },
        ...extras,
      },
    };
  }
  if (type === "press-small") {
    return {
      settings: {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToScroll: 1,
        variableWidth: true,
        lazyLoad: "progressive",
        arrows: false,
        centerMode: true,
        initialSlide: 1,
        responsive: [
          {
            breakpoint: parseInt(gridDesktop, 10),
            settings: {
              dots: true,
            },
          },
        ],
        ...extras,
      },
    };
  }
  if (type === "free-block") {
    return {
      fullWidth: true,
      settings: {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToScroll: 1,
        variableWidth: true,
        lazyLoad: "progressive",
        arrows: true,
        responsive: [
          {
            breakpoint: parseInt(gridDesktop, 10),
            settings: {
              dots: true,
            },
          },
        ],
        ...extras,
      },
    };
  }
  if (type === "lifestyle") {
    return {
      fullWidth: true,
      settings: {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToScroll: 1,
        variableWidth: true,
        lazyLoad: "progressive",
        arrows: false,
        ...extras,
      },
    };
  }
  if (type === "ticker") {
    const baselineLength = 2910;
    const textGutter = 48;
    const baselineSpeed = 60000;
    const avgTextLength = 27;
    const iconHeight = 72;
    const iconGutter = 40;
    // calculate slide speed based on estimated width of text or icon
    let slideSpeed = baselineSpeed;
    if (extras.icon) {
      slideSpeed =
        (((extras.iconHeight / iconHeight) * extras.iconWidth +
          2 * iconGutter) /
          baselineLength) *
        baselineSpeed;
    } else if (extras.text) {
      slideSpeed =
        ((extras.text.length * avgTextLength + 2 * textGutter) /
          baselineLength) *
        baselineSpeed;
    }
    return {
      fullWidth: true,
      settings: {
        dots: false,
        infinite: true,
        speed: slideSpeed,
        slidesToScroll: 1,
        variableWidth: true,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 0,
        cssEase: "linear",
        touchMove: false,
        ...extras,
      },
    };
  }
  if (type === "modal-carousel") {
    return {
      fullWidth: true,
      settings: {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToScroll: 1,
        variableWidth: true,
        lazyLoad: "progressive",
        arrows: true,
        responsive: [
          {
            breakpoint: parseInt(gridTablet, 10),
            settings: {
              arrows: false,
            },
          },
        ],
        ...extras,
      },
    };
  }
  return {};
};

export const getMediaGroupProps = (group) => {
  let previewProps = null;
  if (group.preview && group.previewId) {
    previewProps = () => {
      group.preview.open(group.previewId, group.preview.options);
      group.preview.close();
    };
  }
  const abStateProps = group.abState;
  let route = null;
  if (group.route && group.route.sys) {
    if (group.route.sys.contentType.sys.id === "product") {
      route = getLink({
        type: "products/",
        slug: group.route.shopifyProduct.handle,
        extras: {
          domain: group.domain,
          DIR_MAP: group.DIR_MAP,
        },
      });
    } else if (group.route.sys.contentType.sys.id) {
      route = getLink({
        type: `${
          (group.DIR_MAP || CONSTANTS.DIR_MAP)[
            group.route.sys.contentType.sys.id
          ]
        }/`,
        slug: group.route.slug,
        extras: {
          domain: group.domain,
          DIR_MAP: group.DIR_MAP,
        },
      });
    }
  }
  return {
    props: {
      route,
      preview: previewProps,
      abState: abStateProps,
    },
  };
};

export const getMediaBlockProps = (block) => {
  let previewProps = null;
  if (block.preview && block.previewId) {
    previewProps = () => {
      block.preview.open(block.previewId, block.preview.options);
      block.preview.close();
    };
  }
  const abStateProps =
    block.extras && block.extras.abState ? block.extras.abState : null;
  if (block.type === "ksp") {
    return {
      title: block.title,
      shortText: block.shortText,
      props: {
        mbId: block.id,
        svg:
          block.image &&
          block.image.file &&
          block.image.file.contentType.includes("svg")
            ? block.image.file.url
            : null,
        svgDescription:
          block.image &&
          block.image.file &&
          block.image.file.contentType.includes("svg")
            ? block.image.description
            : null,
        name: block.shortText,
        width:
          (block.image &&
            block.image.file &&
            block.image.file.details &&
            block.image.file.details.image &&
            block.image.file.details.image.width) ||
          null,
        height:
          (block.image &&
            block.image.file &&
            block.image.file.details &&
            block.image.file.details.image &&
            block.image.file.details.image.height) ||
          null,
        preview: previewProps,
        abState: abStateProps,
      },
    };
  }
  if (block.type === "story-block") {
    return {
      title: block.title,
      image: imageTypeFilter(block.image),
      mobileImage: imageTypeFilter(block.mobileImage),
      shortText: block.shortText,
      longText: block.longText && block.longText.longText,
      props: {
        mbId: block.id,
        imagePosition: block.imagePosition || "left",
        backgroundColor: block.backgroundColor,
        video:
          block.video &&
          block.video.file &&
          block.video.file.contentType.includes("video")
            ? block.video.file.url
            : null,
        videoName: (block.video && block.video.title) || "Video",
        videoType:
          block.video && block.video.file && block.video.file.contentType,
        longHtml: block.longText && block.longText.childMarkdownRemark.html,
        preview: previewProps,
        abState: abStateProps,
      },
    };
  }
  if (block.type === "related-block") {
    const cta = {};
    if (block.ctaContent) {
      cta.text = block.ctaText;
      cta.label = block.ctaAccessibleLabel;
      cta.color = block.ctaColor;
      if (block.ctaContent.sys.contentType.sys.id === "product") {
        cta.route = getLink({
          type: "products/",
          slug: block.ctaContent.shopifyProduct.handle,
          extras: block.extras,
        });
      } else if (block.ctaContent.sys.contentType.sys.id) {
        cta.route = getLink({
          type: `${
            (block.DIR_MAP || CONSTANTS.DIR_MAP)[
              block.ctaContent.sys.contentType.sys.id
            ]
          }/`,
          slug: block.ctaContent.slug,
          extras: block.extras,
        });
      }
    }
    if (block.ctaUrl) {
      cta.text = block.ctaText;
      cta.label = block.ctaAccessibleLabel;
      cta.color = block.ctaColor;
      cta.route = getLink({
        item: {
          url: block.ctaUrl,
        },
      });
    }
    return {
      title: block.title,
      image: imageTypeFilter(block.image),
      mobileImage: imageTypeFilter(block.mobileImage),
      shortText: block.shortText,
      longText: block.longText && block.longText.longText,
      props: {
        mbId: block.id,
        cta,
        video:
          block.video &&
          block.video.file &&
          block.video.file.contentType.includes("video")
            ? block.video.file.url
            : null,
        videoName: (block.video && block.video.title) || "Video",
        videoType:
          block.video && block.video.file && block.video.file.contentType,
        longHtml: block.longText && block.longText.childMarkdownRemark.html,
        preview: previewProps,
        abState: abStateProps,
      },
    };
  }
  if (block.type === "quote-hero") {
    const hasSupportingSvg =
      block.supportingImage &&
      block.supportingImage.file &&
      block.supportingImage.file.contentType.includes("svg");
    return {
      title: block.title,
      image: imageTypeFilter(block.image),
      mobileImage: imageTypeFilter(block.mobileImage),
      supportingImage: !hasSupportingSvg ? block.supportingImage : null,
      mobileSupportingImage: !hasSupportingSvg
        ? block.mobileSupportingImage
        : null,
      shortText: block.shortText,
      longText: block.longText && block.longText.longText,
      props: {
        mbId: block.id,
        svg: hasSupportingSvg ? block.supportingImage.file.url : null,
        svgDescription: hasSupportingSvg
          ? block.supportingImage.description
          : null,
        name: (block.supportingImage && block.supportingImage.title) || "Quote",
        imagePosition: block.imagePosition,
        color: block.textColor || "dark",
        width:
          (block.supportingImage &&
            block.supportingImage.file &&
            block.supportingImage.file.details &&
            block.supportingImage.file.details.image &&
            block.supportingImage.file.details.image.width) ||
          null,
        height:
          (block.supportingImage &&
            block.supportingImage.file &&
            block.supportingImage.file.details &&
            block.supportingImage.file.details.image &&
            block.supportingImage.file.details.image.height) ||
          null,
        svgContent:
          block.supportingImage &&
          block.supportingImage.svg &&
          block.supportingImage.svg.content,
        preview: previewProps,
        abState: abStateProps,
      },
    };
  }
  if (block.type === "trial") {
    return {
      title: block.title,
      shortText: block.shortText,
      longText: block.longText && block.longText.longText,
      props: {
        mbId: block.id,
        svg:
          block.image &&
          block.image.file &&
          block.image.file.contentType.includes("svg")
            ? block.image.file.url
            : null,
        svgDescription:
          block.image &&
          block.image.file &&
          block.image.file.contentType.includes("svg")
            ? block.image.description
            : null,
        name: block.shortText,
        width:
          (block.image &&
            block.image.file &&
            block.image.file.details &&
            block.image.file.details.image &&
            block.image.file.details.image.width) ||
          null,
        height:
          (block.image &&
            block.image.file &&
            block.image.file.details &&
            block.image.file.details.image &&
            block.image.file.details.image.height) ||
          null,
        longHtml: block.longText && block.longText.childMarkdownRemark.html,
        preview: previewProps,
        abState: abStateProps,
      },
    };
  }
  if (block.type === "collection") {
    const showPrice = true;
    // block.extras &&
    // block.extras.classes &&
    // block.extras.classes.includes("show-price");
    const showDiscount =
      block.extras &&
      block.extras.classes &&
      block.extras.classes.includes("show-discount");
    const showColor =
      block.extras &&
      block.extras.classes &&
      block.extras.classes.includes("show-color");
    const showReview =
      block.extras &&
      block.extras.classes &&
      block.extras.classes.includes("show-review") &&
      !isGiftCardProduct(block.shopifyProduct);

    // Collection Bundles
    if (block.bundles) {
      const fromPriceInfo = block.bundles.map((bundle) => {
        const bundleInfo = bundle.items.map((i) => {
          const itemPrices = i.products.map((p) => {
            const bundleProductQuantity =
              block.extras && block.extras.getBundleProductQuantity
                ? block.extras.getBundleProductQuantity({
                    product: p,
                    bundles: block.bundles,
                  })
                : 1;

            const fromPrice =
              getFromPrice(p.shopifyProduct.variants.edges) *
              bundleProductQuantity;
            return {
              id: p.id,
              fromPrice,
              priceInfo: (fromPrice &&
                showDiscount &&
                block &&
                block.extras &&
                block.extras.calcDiscountPrice &&
                block.extras.calcDiscountPrice({
                  bundleInfo:
                    block.extras.findProductBundle &&
                    block.extras.findProductBundle(
                      null,
                      p,
                      block.bundles,
                      bundleProductQuantity,
                      true,
                    ),
                  price: Math.trunc(fromPrice),
                  lineItems:
                    (block.extras.shopifyContext &&
                      block.extras.shopifyContext.store.checkout.lineItems) ||
                    [],
                  product: p.shopifyProduct,
                  // variant: activeVariant,
                  discounts: block.extras.discounts,
                  quantity: bundleProductQuantity,
                  params: block.extras.params,
                })) || {
                price: Math.trunc(fromPrice),
              },
            };
          });
          return itemPrices.reduce((prev, curr) =>
            prev.fromPrice < curr.fromPrice ? prev : curr,
          );
        });
        return {
          bundleInfo,
          bundlePrice: bundleInfo.reduce((a, b) => a + b.fromPrice, 0),
        };
      });
      // Format priceInfo
      const bundlePriceInfo = fromPriceInfo.reduce((prev, curr) =>
        prev.bundlePrice < curr.bundlePrice ? prev : curr,
      );
      const priceInfo = {
        price: bundlePriceInfo.bundlePrice,
      };
      bundlePriceInfo.bundleInfo.map((bi) => {
        Object.entries(bi.priceInfo).map(([k, v]) => {
          if (k === "valid") {
            if (priceInfo.valid === undefined || v) {
              priceInfo[k] = v;
            }
          } else if (k === "skipDisplay") {
            if (priceInfo.skipDisplay === undefined || v) {
              priceInfo[k] = v;
            }
          } else if (k === "newPrice") {
            if (priceInfo.newPrice === undefined) {
              priceInfo[k] = v;
            } else {
              priceInfo[k] = (parseFloat(priceInfo[k]) + parseFloat(v)).toFixed(
                2,
              );
            }
          } else if (k !== "price") {
            priceInfo[k] = v;
          }
          // Case where a bundle item has no discount
          if (!bi.priceInfo.newPrice) {
            priceInfo.newPrice =
              priceInfo.newPrice === undefined
                ? bi.fromPrice.toFixed(2)
                : (
                    parseFloat(priceInfo.newPrice) + parseFloat(bi.fromPrice)
                  ).toFixed(2);
          }
          return true;
        });
        return true;
      });

      // Find special images to use
      let specialImage = null;
      if (
        block.specialCollectionImages &&
        block.extras &&
        block.extras.classes
      ) {
        specialImage = block.specialCollectionImages.find(
          (sci) =>
            sci.classNames &&
            sci.classNames.find((cn) => block.extras.classes.includes(cn)),
        );
      }

      return {
        title: block.title,
        image: block.collectionImage,
        mobileImage: block.mobileCollectionImage,
        extraText: showPrice ? `From $${bundlePriceInfo.bundlePrice}` : null,
        shortText: block.plpName || block.heading,
        longText: block.shortDescription,
        supportingImage: block.supportingImage,
        mobileSupportingImage: block.mobileSupportingImage,
        props: {
          mbId: block.id,
          cta: {
            route: getLink({
              type: "bundle/",
              slug: block.slug,
              extras: block.extras,
            }),
          },
          new: block.new,
          priceInfo,
          activeDiscount: block.extras && block.extras.activeDiscount,
          specialImage: specialImage && specialImage.image,
          mobileSpecialImage: specialImage && specialImage.mobileImage,
          carouselClasses: block.extras && block.extras.classes,
          isGiftCard: false,
          isBundle: true,
          useMemo: block.extras && block.extras.useMemo,
          preview: previewProps,
          abState: abStateProps,
        },
      };
    }

    let sortedColors = showColor && block.extras && block.extras.colors;
    // Sort Contentful colors according to Shopify colors
    if (
      showColor &&
      block.shopifyProduct.options &&
      block.extras &&
      block.extras.colors
    ) {
      const shopifyColors = block.shopifyProduct.options.find(
        (c) => c.name.toLowerCase() === "color",
      );
      if (shopifyColors) {
        sortedColors = [...block.extras.colors].sort(
          (a, b) =>
            shopifyColors.values.indexOf(a.node.shopifyColorOption.color) -
            shopifyColors.values.indexOf(b.node.shopifyColorOption.color),
        );
      }
    }
    // Get default color index if there is one
    const defaultColorIndex =
      sortedColors && block.plpDefaultColor
        ? sortedColors.findIndex(
            (sc) =>
              block.plpDefaultColor.shopifyColorOption.id ===
              sc.node.shopifyColorOption.id,
          )
        : 0;

    const fromPrice =
      showPrice && block.shopifyProduct.variants
        ? getFromPrice(block.shopifyProduct.variants.edges)
        : null;
    const priceInfo = (fromPrice &&
      showDiscount &&
      block &&
      block.extras &&
      block.extras.calcDiscountPrice &&
      block.extras.calcDiscountPrice({
        bundleInfo:
          block.extras.findProductBundle &&
          block.extras.findProductBundle(
            null,
            block,
            block.extras.singleItemBundles,
            1,
            true,
          ),
        price: Math.trunc(fromPrice),
        lineItems:
          (block.extras.shopifyContext &&
            block.extras.shopifyContext.store.checkout.lineItems) ||
          [],
        product: block.shopifyProduct,
        // variant: activeVariant,
        discounts: block.extras.discounts,
        quantity: 1,
        params: block.extras.params,
      })) || {
      price: Math.trunc(fromPrice),
    };

    // Find special images to use
    let specialImage = null;
    if (block.specialCollectionImages && block.extras && block.extras.classes) {
      specialImage = block.specialCollectionImages.find(
        (sci) =>
          sci.classNames &&
          sci.classNames.find((cn) => block.extras.classes.includes(cn)),
      );
    }

    return {
      title: block.title,
      image: block.collectionImage,
      mobileImage: block.mobileCollectionImage,
      extraText:
        showPrice && block.shopifyProduct.variants
          ? `From $${fromPrice}`
          : null,
      shortText: block.plpName || block.heading,
      longText: block.shortDescription,
      supportingImage: block.supportingImage,
      mobileSupportingImage: block.mobileSupportingImage,
      props: {
        mbId: block.id,
        cta: {
          route: getLink({
            type: "products/",
            slug: block.shopifyProduct.handle,
            extras: block.extras,
          }),
        },
        colors: sortedColors,
        hideOptions:
          block.hideOptions &&
          block.hideOptions.map((ho) => {
            if (ho.shopifyColorOption) {
              return {
                shopifyOption: {
                  name: ho.shopifyColorOption.color,
                },
              };
            }
            return ho;
          }),
        defaultColorIndex: defaultColorIndex < 0 ? 0 : defaultColorIndex,
        new: block.new,
        priceInfo,
        activeDiscount: block.extras && block.extras.activeDiscount,
        specialImage: specialImage && specialImage.image,
        mobileSpecialImage: specialImage && specialImage.mobileImage,
        carouselClasses: block.extras && block.extras.classes,
        isGiftCard: isGiftCardProduct(block.shopifyProduct),
        isBundle: false,
        useMemo: block.extras && block.extras.useMemo,
        reviewInfo:
          showReview && block.extras
            ? {
                details: block.extras.review && block.extras.review.details,
                reviewStars: block.extras.reviewStars,
              }
            : null,
        preview: previewProps,
        abState: abStateProps,
      },
    };
  }
  if (block.type === "card") {
    const cta = {
      className: block.carouselSlider
        ? "shared__button--link"
        : "shared__button--outlined",
    };
    if (block.ctaContent) {
      cta.text = block.ctaText;
      cta.label = block.ctaAccessibleLabel;
      cta.color = block.ctaColor;
      if (block.ctaContent.sys.contentType.sys.id === "product") {
        cta.route = getLink({
          type: "products/",
          slug: block.ctaContent.shopifyProduct.handle,
          extras: block.extras,
        });
      } else if (block.ctaContent.sys.contentType.sys.id) {
        cta.route = getLink({
          type: `${
            (block.DIR_MAP || CONSTANTS.DIR_MAP)[
              block.ctaContent.sys.contentType.sys.id
            ]
          }/`,
          slug: block.ctaContent.slug,
          extras: block.extras,
        });
      }
    }
    if (block.ctaUrl) {
      cta.text = block.ctaText;
      cta.label = block.ctaAccessibleLabel;
      cta.color = block.ctaColor;
      cta.route = getLink({
        item: {
          url: block.ctaUrl,
        },
      });
    }
    return {
      title: block.title,
      image: imageTypeFilter(block.image),
      mobileImage: imageTypeFilter(block.mobileImage),
      shortText: block.shortText,
      longText: block.longText && block.longText.longText,
      props: {
        mbId: block.id,
        imagePosition: block.imagePosition || "left",
        cta,
        useSlider: block.carouselSlider,
        displayTitle: block.displayTitle,
        // useGutters: block.useGutters,
        // video:
        //   block.video &&
        //   block.video.file &&
        //   block.video.file.contentType.includes("video")
        //     ? block.video.file.url
        //     : null,
        // videoName: (block.video && block.video.title) || "Video",
        // videoType:
        //   block.video && block.video.file && block.video.file.contentType,
        longHtml: block.longText && block.longText.childMarkdownRemark.html,
        preview: previewProps,
        abState: abStateProps,
      },
    };
  }
  if (block.type === "sticker") {
    return {
      title: block.title,
      props: {
        mbId: block.id,
        svg:
          block.image &&
          block.image.file &&
          block.image.file.contentType.includes("svg")
            ? block.image.file.url
            : null,
        svgDescription:
          block.image &&
          block.image.file &&
          block.image.file.contentType.includes("svg")
            ? block.image.description
            : null,
        name: (block.image && block.image.title) || "Sticker",
        imagePosition: block.imagePosition,
        width:
          (block.image &&
            block.image.file &&
            block.image.file.details &&
            block.image.file.details.image &&
            block.image.file.details.image.width) ||
          null,
        height:
          (block.image &&
            block.image.file &&
            block.image.file.details &&
            block.image.file.details.image &&
            block.image.file.details.image.height) ||
          null,
        preview: previewProps,
        abState: abStateProps,
      },
    };
  }
  if (block.type === "ticker") {
    return {
      title: block.title,
      shortText: block.shortText,
      props: {
        mbId: block.id,
        backgroundColor: block.backgroundColor,
        svg:
          block.image &&
          block.image.file &&
          block.image.file.contentType.includes("svg")
            ? block.image.file.url
            : null,
        svgDescription:
          block.image &&
          block.image.file &&
          block.image.file.contentType.includes("svg")
            ? block.image.description
            : null,
        name: (block.image && block.image.title) || "Ticker",
        imagePosition: block.imagePosition,
        width:
          (block.image &&
            block.image.file &&
            block.image.file.details &&
            block.image.file.details.image &&
            block.image.file.details.image.width) ||
          null,
        height:
          (block.image &&
            block.image.file &&
            block.image.file.details &&
            block.image.file.details.image &&
            block.image.file.details.image.height) ||
          null,
        preview: previewProps,
        abState: abStateProps,
      },
    };
  }
  if (block.type === "review") {
    return {
      title: "Review",
      props: {
        mbId: block.id,
        review: block.review,
        reviewStar: block.reviewStar,
        reviewModal: {
          setShowReviewModal: block.setShowReviewModal,
          setReviewModalContent: block.setReviewModalContent,
        },
        preview: true,
      },
    };
  }
  if (block.type === "hero") {
    const cta = {};
    if (block.ctaContent) {
      cta.text = block.ctaText;
      cta.label = block.ctaAccessibleLabel;
      cta.color = block.ctaColor;
      if (block.ctaContent.sys.contentType.sys.id === "product") {
        cta.route = getLink({
          type: "products/",
          slug: block.ctaContent.shopifyProduct.handle,
          extras: block.extras,
        });
      } else if (block.ctaContent.sys.contentType.sys.id) {
        cta.route = getLink({
          type: `${
            (block.DIR_MAP || CONSTANTS.DIR_MAP)[
              block.ctaContent.sys.contentType.sys.id
            ]
          }/`,
          slug: block.ctaContent.slug,
          extras: block.extras,
        });
      }
    }
    if (block.ctaUrl) {
      cta.text = block.ctaText;
      cta.label = block.ctaAccessibleLabel;
      cta.color = block.ctaColor;
      cta.route = getLink({
        item: {
          url: block.ctaUrl,
        },
      });
    }
    return {
      title: block.title,
      image: imageTypeFilter(block.image),
      mobileImage: imageTypeFilter(block.mobileImage),
      supportingImage: imageTypeFilter(block.supportingImage),
      mobileSupportingImage: imageTypeFilter(block.mobileSupportingImage),
      shortText: block.shortText,
      longText: block.longText && block.longText.longText,
      props: {
        mbId: block.id,
        cta,
        color: block.textColor || "dark",
        useGutters: block.useGutters,
        backgroundColor: block.backgroundColor,
        imagePosition: block.imagePosition,
        displayTitle: block.displayTitle,
        longHtml: block.longText && block.longText.childMarkdownRemark.html,
        preview: previewProps,
        abState: abStateProps,
      },
    };
  }
  if (block.type === "product-block" || block.type === "guide-item") {
    const cta = {};
    if (block.ctaContent) {
      cta.text = block.ctaText;
      cta.label = block.ctaAccessibleLabel;
      cta.color = block.ctaColor;
      if (block.ctaContent.sys.contentType.sys.id === "product") {
        cta.route = getLink({
          type: "products/",
          slug: block.ctaContent.shopifyProduct.handle,
          extras: block.extras,
        });
      } else if (block.ctaContent.sys.contentType.sys.id) {
        cta.route = getLink({
          type: `${
            (block.DIR_MAP || CONSTANTS.DIR_MAP)[
              block.ctaContent.sys.contentType.sys.id
            ]
          }/`,
          slug: block.ctaContent.slug,
          extras: block.extras,
        });
      }
    }
    if (block.ctaUrl) {
      cta.text = block.ctaText;
      cta.label = block.ctaAccessibleLabel;
      cta.color = block.ctaColor;
      cta.route = getLink({
        item: {
          url: block.ctaUrl,
        },
      });
    }
    return {
      title: block.title,
      image: imageTypeFilter(block.image),
      mobileImage: imageTypeFilter(block.mobileImage),
      shortText: block.shortText,
      longText: block.longText && block.longText.longText,
      props: {
        mbId: block.id,
        imagePosition: block.imagePosition || "left",
        cta,
        useGutters: block.useGutters,
        video:
          block.video &&
          block.video.file &&
          block.video.file.contentType.includes("video")
            ? block.video.file.url
            : null,
        videoName: (block.video && block.video.title) || "Video",
        videoType:
          block.video && block.video.file && block.video.file.contentType,
        longHtml: block.longText && block.longText.childMarkdownRemark.html,
        preview: previewProps,
        abState: abStateProps,
      },
    };
  }
  if (block.type === "press-small") {
    return {
      title: block.title,
      shortText: block.shortText,
      props: {
        mbId: block.id,
        svg:
          block.image &&
          block.image.file &&
          block.image.file.contentType.includes("svg")
            ? block.image.file.url
            : null,
        svgDescription:
          block.image &&
          block.image.file &&
          block.image.file.contentType.includes("svg")
            ? block.image.description
            : null,
        name: (block.image && block.image.title) || "Press",
        width:
          (block.image &&
            block.image.file &&
            block.image.file.details &&
            block.image.file.details.image &&
            block.image.file.details.image.width) ||
          null,
        height:
          (block.image &&
            block.image.file &&
            block.image.file.details &&
            block.image.file.details.image &&
            block.image.file.details.image.height) ||
          null,
        preview: previewProps,
        abState: abStateProps,
      },
    };
  }
  if (block.type === "free-block") {
    const cta = {};
    if (block.ctaContent) {
      cta.text = block.ctaText;
      cta.label = block.ctaAccessibleLabel;
      cta.color = block.ctaColor;
      if (block.ctaContent && block.ctaContent.sys) {
        if (block.ctaContent.sys.contentType.sys.id === "product") {
          cta.route = getLink({
            type: "products/",
            slug: block.ctaContent.shopifyProduct.handle,
            extras: block.extras,
          });
        } else if (block.ctaContent.sys.contentType.sys.id) {
          cta.route = getLink({
            type: `${
              (block.DIR_MAP || CONSTANTS.DIR_MAP)[
                block.ctaContent.sys.contentType.sys.id
              ]
            }/`,
            slug: block.ctaContent.slug,
            extras: block.extras,
          });
        }
      }
    }
    if (block.ctaUrl) {
      cta.text = block.ctaText;
      cta.label = block.ctaAccessibleLabel;
      cta.color = block.ctaColor;
      cta.route = getLink({
        item: {
          url: block.ctaUrl,
        },
      });
      if (cta.route === "#") {
        cta.inactive = true;
      }
    }
    return {
      title: block.title,
      image: imageTypeFilter(block.image),
      mobileImage: imageTypeFilter(block.mobileImage),
      shortText: block.shortText,
      longText: block.longText && block.longText.longText,
      supportingImage: block.supportingImage,
      mobileSupportingImage: block.mobileSupportingImage,
      props: {
        mbId: block.id,
        svg:
          block.supportingImage &&
          block.supportingImage.file &&
          block.supportingImage.file.contentType.includes("svg")
            ? block.supportingImage.file.url
            : null,
        svgDescription:
          block.supportingImage &&
          block.supportingImage.file &&
          block.supportingImage.file.contentType.includes("svg")
            ? block.supportingImage.description
            : null,
        name: (block.supportingImage && block.supportingImage.title) || "Quote",
        color: block.textColor || "dark",
        cta,
        longHtml: block.longText && block.longText.childMarkdownRemark.html,
        imagePosition: block.imagePosition,
        imageStyle: block.imageStyle,
        supportingImageStyle: block.supportingImageStyle,
        backgroundColor: block.backgroundColor,
        width:
          (block.supportingImage &&
            block.supportingImage.file &&
            block.supportingImage.file.details &&
            block.supportingImage.file.details.image &&
            block.supportingImage.file.details.image.width) ||
          null,
        height:
          (block.supportingImage &&
            block.supportingImage.file &&
            block.supportingImage.file.details &&
            block.supportingImage.file.details.image &&
            block.supportingImage.file.details.image.height) ||
          null,
        displayTitle: block.displayTitle,
        preview: previewProps,
        abState: abStateProps,
      },
    };
  }
  if (block.type === "info-block") {
    return {
      title: block.title,
      shortText: block.shortText,
      longText: block.longText && block.longText.childMarkdownRemark.html,
      props: {
        mbId: block.id,
        iconColor: block.carouselTextColor,
        preview: previewProps,
        abState: abStateProps,
      },
    };
  }
  if (block.type === "video") {
    return {
      title: block.title,
      props: {
        mbId: block.id,
        video:
          block.video &&
          block.video.file &&
          block.video.file.contentType.includes("video")
            ? block.video.file.url
            : null,
        name: (block.video && block.video.title) || "Video",
        type: block.video && block.video.file && block.video.file.contentType,
        preview: previewProps,
        abState: abStateProps,
      },
    };
  }
  if (block.type === "image") {
    return {
      title: block.title,
      image: imageTypeFilter(block.image),
      mobileImage: imageTypeFilter(block.mobileImage),
      props: {
        mbId: block.id,
        backgroundColor: block.backgroundColor,
        preview: previewProps,
        abState: abStateProps,
      },
    };
  }
  if (block.type === "text") {
    return {
      title: block.title,
      longText: block.longText && block.longText.longText,
      props: {
        mbId: block.id,
        backgroundColor: block.backgroundColor,
        longHtml: block.longText && block.longText.childMarkdownRemark.html,
        preview: previewProps,
        abState: abStateProps,
      },
    };
  }
  if (block.type === "editorial" || block.type === "large-editorial") {
    return {
      title: block.title,
      longText: block.longText && block.longText.longText,
      props: {
        mbId: block.id,
        backgroundColor: block.backgroundColor,
        longHtml: block.longText && block.longText.childMarkdownRemark.html,
        preview: previewProps,
        abState: abStateProps,
      },
    };
  }
  if (block.type === "lifestyle") {
    return {
      title: block.title,
      useTitle: true,
      image: imageTypeFilter(block.image),
      mobileImage: imageTypeFilter(block.mobileImage),
      props: {
        mbId: block.id,
        preview: previewProps,
        abState: abStateProps,
      },
    };
  }
  if (block.type === "email-hero") {
    return {
      title: block.title,
      shortText: block.shortText,
      longText: block.longText && block.longText.longText,
      props: {
        mbId: block.id,
        useGutters: block.useGutters,
        color: block.textColor || "dark",
        backgroundColor: block.backgroundColor,
        oosEmailList: block.extras && block.extras.OOS_EMAIL_LIST,
        extras: {
          DEV: block.extras && block.extras.DEV,
          KLAVIYO_API_KEY: block.extras && block.extras.KLAVIYO_API_KEY,
          CORS_PROXY: block.extras && block.extras.CORS_PROXY,
        },
        preview: previewProps,
        abState: abStateProps,
      },
    };
  }
  if (block.type === "pencilbar") {
    const cta = {
      text: block.shortText || block.ctaText || "",
      color: block.backgroundColor || block.ctaColor,
    };
    if (block.ctaContent) {
      cta.text = block.ctaText || "";
      cta.color = block.ctaColor;
      if (block.ctaContent && block.ctaContent.sys) {
        if (block.ctaContent.sys.contentType.sys.id === "product") {
          cta.route = getLink({
            type: "products/",
            slug: block.ctaContent.shopifyProduct.handle,
            extras: block.extras,
          });
        } else if (block.ctaContent.sys.contentType.sys.id) {
          cta.route = getLink({
            type: `${
              (block.DIR_MAP || CONSTANTS.DIR_MAP)[
                block.ctaContent.sys.contentType.sys.id
              ]
            }/`,
            slug: block.ctaContent.slug,
            extras: block.extras,
          });
        }
      }
    }
    if (block.ctaUrl) {
      cta.text = block.ctaText || "";
      cta.color = block.ctaColor;
      cta.route = getLink({
        item: {
          url: block.ctaUrl,
        },
      });
      if (cta.route === "#") {
        cta.inactive = true;
      }
    }
    return {
      title: block.title,
      useTitle: true,
      image: block.image,
      props: {
        mbId: block.id,
        cta,
        displayTitle: block.displayTitle,
        preview: previewProps,
        abState: abStateProps,
      },
    };
  }
  return {
    props: {
      mbId: block.id,
      preview: previewProps,
      abState: abStateProps,
    },
  };
};

export const buildCustomAttributes = ({ tags, giftOptions, altProps }) => {
  const customAttr = [];
  if (tags.includes("trial")) {
    customAttr.push({
      key: "trial_product",
      value: tags.includes("trial").toString(),
    });
  }
  if (tags.includes("gift-card")) {
    customAttr.push({
      key: "gift_card",
      value: tags.includes("gift-card").toString(),
    });
  }
  if (giftOptions && (giftOptions.note || giftOptions.name)) {
    customAttr.push({
      key: "gift_name",
      value: giftOptions.name,
    });
    customAttr.push({
      key: "gift_note",
      value: giftOptions.note,
    });
  }
  // Add any alt attributes
  if (altProps) {
    Object.entries(altProps).map(([k, v]) => {
      customAttr.push({
        key: k,
        value: v.toString(),
      });
      return false;
    });
  }
  return customAttr;
};

export const buildTrialPropertyString = ({ tags }) =>
  `&properties[trial_product]=${tags.includes("trial").toString()}`;

export const lineItemPrice = (lineItem, quantity, altFunction) => {
  const itemFullPrice = lineItem.variant.price.amount;
  // Get discounted value
  const bundleDiscount = lineItem.customAttributes.find(
    (ca) => ca.key === "bundle_discount",
  );
  const itemBundleDiscount = bundleDiscount ? bundleDiscount.value / 100 : 0;
  // $0 if trial procuct
  const itemTrial = lineItem.customAttributes.find(
    (ca) =>
      ca.key === "trial_product" &&
      ca.value &&
      ca.value.toLowerCase() === "true",
  );
  // check for alt functionality prop of show_storefront_price
  const showPrice =
    altFunction &&
    altFunction.CART &&
    altFunction.CART.props &&
    altFunction.CART.props.show_storefront_price;
    
  const itemPrice = Math.trunc(itemFullPrice * quantity - itemBundleDiscount);
  return itemPrice;
};

export const addProductToCart = async ({
  e,
  currentBundle,
  currentVariant,
  currentProduct,
  currentQuantity,
  addVariantToCart,
  oosQuantity,
  giftOptions,
  altProps,
}) => {
  e.preventDefault();
  if (currentBundle && !currentBundle.upsell && currentBundle.items) {
    // make sure every item in the bundle is available
    if (
      currentBundle.items.every(
        (i) =>
          i.variant &&
          i.product &&
          isAvailable(i.variant, oosQuantity, i.product),
      )
    ) {
      // Build list of variants, quantities and custom attributes
      const variantList = [];
      const quantityList = [];
      const attrList = [];
      currentBundle.items.map((i) => {
        variantList.push(i.variant.id);
        quantityList.push(i.quantity || 1);
        attrList.push(
          buildCustomAttributes({
            tags: i.product.shopifyProduct.tags,
            giftOptions: isGiftCardProduct(i.product) && giftOptions,
            altProps,
          }),
        );
        return true;
      });

      await addVariantToCart(variantList, quantityList, attrList);
    }
  } else if (
    currentVariant &&
    currentProduct &&
    isAvailable(currentVariant, oosQuantity, currentProduct)
  ) {
    const customAttributes = buildCustomAttributes({
      tags: currentProduct.shopifyProduct.tags,
      giftOptions: isGiftCardProduct(currentProduct) && giftOptions,
      altProps,
    });
    // Add upsell item if exists
    if (
      currentBundle &&
      currentBundle.upsell &&
      currentBundle.index !== null &&
      currentBundle.items
    ) {
      if (
        currentBundle.items.every(
          (i) =>
            i.tempVariant &&
            i.product &&
            isAvailable(i.tempVariant, oosQuantity, i.product),
        )
      ) {
        // Build list of variants, quantities and custom attributes
        const variantList = [currentVariant.id];
        const quantityList = [currentQuantity || 1];
        const attrList = [customAttributes];
        currentBundle.items.map((i) => {
          variantList.push(i.tempVariant.id);
          quantityList.push(1);
          attrList.push(
            buildCustomAttributes({
              tags: i.product.shopifyProduct.tags,
              giftOptions: isGiftCardProduct(i.product) && giftOptions,
              altProps,
            }),
          );
          return true;
        });

        await addVariantToCart(variantList, quantityList, attrList);
      }
    } else {
      await addVariantToCart(
        currentVariant.id,
        currentQuantity || 1,
        customAttributes,
      );
    }
    // trackCartPixel(productTitle, active)
    // window.open(checkout.webUrl)
  }
};

export const corsFetch = ({ url, data, corsProxy, dev }) => {
  const isBrowser = typeof window !== `undefined`;
  return dev &&
    isBrowser &&
    !window.location.host.includes(process.env.DEV_PORT)
    ? Axios.post(
        `${isE2E ? "" : corsProxy || CONSTANTS.CORS_PROXY}${url}`,
        data,
      )
    : Axios.post("/.netlify/functions/api-call", {
        api_url: url,
        data,
      });
};

export const calcBundleDiscountPrice = (bundleInfo, price) => {
  if (bundleInfo.discount.discountType === "fixed") {
    return Math.trunc(price - bundleInfo.discount.discountValue);
  }
  if (bundleInfo.discount.discountType === "percent") {
    return (price - price * (bundleInfo.discount.discountValue / 100)).toFixed(
      2,
    );
  }
  return price;
};

export const findLongestWord = (str) => {
  if (typeof str !== "string") {
    return 0;
  }
  const longestWord = str
    .split(" ")
    .reduce(
      (longest, currentWord) =>
        currentWord.length > longest.length ? currentWord : longest,
      "",
    );
  return longestWord.length;
};

export const emailIsValid = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

export const listSearch = ({ list, search, prefix, init }) => {
  const newList = list
    .filter((l) => l.includes(search))
    .map((l) => `${prefix || ""}${l}`);
  if (init && newList.length) {
    newList.push(init);
  }
  return newList;
};

export const getActiveColor = ({ activeVariant, productColors }) => {
  if (!activeVariant || !activeVariant.title) {
    return null;
  }
  const activeOpts = activeVariant.title.split(" / ");
  const activeProductColor = productColors.find((color) => {
    const colorName = color.node.shopifyColorOption.color;
    return activeOpts.includes(colorName);
  });
  return activeProductColor && activeProductColor.node;
};

export const calculateBundlePrice = (currentBundle, params) => {
  const usePriceInfo =
    currentBundle &&
    currentBundle.started &&
    params &&
    currentBundle.items.every(
      (i) =>
        i.priceInfo &&
        i.priceInfo.newPrice &&
        (i.priceInfo.discountName || i.priceInfo.bundleDiscountName) &&
        i.priceInfo.valid &&
        i.priceInfo.newPrice !== i.priceInfo.price &&
        !i.priceInfo.skipDisplay,
    );

  const regularPrice =
    currentBundle &&
    currentBundle.items.reduce((a, b, i) => {
      if (!currentBundle.started) {
        return (
          a +
          ((b.product &&
            getFromPrice(b.product.shopifyProduct.variants.edges) *
              (b.quantity || 1)) ||
            0)
        );
      }
      const itemValue =
        b.priceInfo && (b.variant || currentBundle.index === i)
          ? parseFloat(b.priceInfo.price)
          : getFromPrice(b.product.shopifyProduct.variants.edges) *
            (b.quantity || 1);
      return a + itemValue;
    }, 0);

  let newPrice = null;
  if (usePriceInfo) {
    newPrice =
      currentBundle &&
      currentBundle.items.reduce((a, b, i) => {
        // Use price info if item is set or active, else use cheapest variant
        if (b.variant || currentBundle.index === i) {
          return b.priceInfo
            ? a + parseFloat(b.priceInfo.newPriceRaw)
            : a +
                getFromPrice(b.product.shopifyProduct.variants.edges) *
                  (b.quantity || 1);
        }
        return b.fromPriceInfo
          ? a + parseFloat(b.fromPriceInfo.newPriceRaw)
          : a +
              getFromPrice(b.product.shopifyProduct.variants.edges) *
                (b.quantity || 1);
      }, 0);
  }
  return {
    regularPrice,
    newPrice,
  };
};
