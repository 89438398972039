import React from "react";
import PropTypes from "prop-types";

function HamburgerIcon({ color }) {
  return (
    <svg
      width="24px"
      height="18px"
      viewBox="0 0 24 18"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink">
      <title>Hamburger</title>
      <desc>Hamburger Icon</desc>
      <g
        id="Grids-Nav-Footer"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd">
        <g id="Tablet-Expanded" transform="translate(-16.000000, -22.000000)">
          <rect fill="transparent" x="0" y="0" width="768" height="64" />
          <g
            id="Group"
            transform="translate(16.000000, 22.000000)"
            className={`shared__fill-${color}`}>
            <rect x="0" y="0" width="24" height="2" rx="1" />
            <rect x="0" y="8" width="24" height="2" rx="1" />
            <rect x="0" y="16" width="24" height="2" rx="1" />
          </g>
        </g>
      </g>
    </svg>
  );
}

HamburgerIcon.propTypes = {
  color: PropTypes.string,
};

HamburgerIcon.defaultProps = {
  color: "defaultText",
};

export default HamburgerIcon;
