function supportsResizeObserver() {
  return (
    "ResizeObserver" in global &&
    "ResizeObserverEntry" in global &&
    "contentRect" in ResizeObserverEntry.prototype
  );
}

export const onClientEntry = () => {
  // IntersectionObserver polyfill for gatsby-background-image (Safari, IE)
  if (!(`IntersectionObserver` in window)) {
    import(`intersection-observer`);
    console.log(`# IntersectionObserver is polyfilled!`);
  }
  if (!supportsResizeObserver()) {
    import("resize-observer-polyfill");
    console.log(`# ResizeObserver is polyfilled!`);
  }
};

export const shouldUpdateScroll = ({
  routerProps,
  prevRouterProps,
  getSavedScrollPosition,
}) => {
  // Only get curr position if we are not changing options
  // setTimeout(() => {
  //   console.log("saved pos", cp);
  //   window.scrollTo(...cp);
  // }, 1000);
  if (
    !prevRouterProps ||
    (prevRouterProps &&
      routerProps.location.pathname !== prevRouterProps.location.pathname)
  ) {
    const currentPosition = getSavedScrollPosition(routerProps.location);
    setTimeout(() => {
      window.scrollTo(...(currentPosition || [0, 0]));
    }, 500);
    // window.scrollTo(...(currentPosition || [0, 0]));
  }
  return false;
};

export const onServiceWorkerUpdateReady = () => window.location.reload(true);
