import { useCallback, useState } from "react";
import useIsomorphicLayoutEffect from "./useIsomorphicLayoutEffect";

const isBrowser = typeof window !== `undefined`;

/**
 * read and write url hash, response to url hash change
 */
const useHash = () => {
  const [hash, updateHash] = useState(() =>
    isBrowser ? window.location.hash : null,
  );

  const onHashChange = useCallback(() => {
    updateHash(window.location.hash);
  }, []);

  useIsomorphicLayoutEffect(() => {
    if (!isBrowser) {
      return () => {};
    }

    window.addEventListener("hashchange", onHashChange);

    return () => {
      window.removeEventListener("hashchange", onHashChange);
    };
  });

  const setHash = useCallback(
    (newHash) => {
      if (newHash !== hash) {
        if (newHash) {
          window.location.hash = newHash;
        } else {
          window.location.hash = "";
          const uri = window.location.toString();
          const cleanUri = uri.substring(0, uri.indexOf("#"));

          window.history.replaceState({}, document.title, cleanUri);
        }
      }
    },
    [hash],
  );

  return [hash, setHash];
};

export default useHash;
