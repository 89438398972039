import React from "react";
import PropTypes from "prop-types";

function CaretIcon({ color, className, onClick, width }) {
  let iconClasses = ["shared__icon", "caret"];
  if (className) {
    iconClasses = iconClasses.concat(
      Array.isArray(className) ? className : className.split(" "),
    );
  }
  return (
    <svg
      className={iconClasses.join(" ")}
      onClick={onClick}
      onKeyPress={onClick}
      width={`${width}px`}
      height={`${width}px`}
      viewBox={`0 0 ${width} ${width}`}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink">
      <title>Caret</title>
      <g
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        className="svg__path">
        <polygon
          className={`shared__fill-${color}`}
          fillRule="nonzero"
          points="21.2928932 12.2928932 22.7071068 13.7071068 16 20.4142136 9.29289322 13.7071068 10.7071068 12.2928932 16 17.585"
        />
        <polygon points={`0 0 ${width} 0 ${width} ${width} 0 ${width}`} />
      </g>
    </svg>
  );
}

CaretIcon.propTypes = {
  color: PropTypes.string,
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  onClick: PropTypes.func,
  width: PropTypes.number,
};

CaretIcon.defaultProps = {
  color: "defaultText",
  className: "",
  onClick: () => {},
  width: 32,
};

CaretIcon.displayName = "CaretIcon";

export default CaretIcon;
