import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import BuffyLogo from "../atoms/icons/buffy-logo";
import HamburgerIcon from "../atoms/icons/hamburger-icon";
// import PropTypes from "prop-types";
// import LatticeGrid from "lattice/lib/components/LatticeGrid";
import useHover from "../../hooks/hover";
import Accordion from "../molecules/accordion";

function DesignHeader({ menu, current }) {
  const [submenuRef, submenuEl, submenuIsHovered] = useHover(true);
  const [submenuState, setSubmenuState] = useState("init");
  const [submenuAnimateState, setSubmenuAnimateState] = useState(null);
  const mobileExpand = useRef();

  const handleAnimationStart = () => {
    setSubmenuAnimateState("animating");
  };
  const handleAnimationEnd = () => {
    setSubmenuAnimateState("ending");
    if (submenuState === "hidden") {
      setSubmenuState("init");
    }
  };

  const handleMobileMenu = () => {
    if (submenuAnimateState === "animating") {
      return;
    }

    if (submenuState === "show") {
      setSubmenuState("hidden");
    } else {
      setSubmenuState("show");
    }
  };

  useEffect(() => {
    if (submenuEl) {
      if (submenuState === "show") {
        disableBodyScroll(submenuEl);
      } else {
        enableBodyScroll(submenuEl);
      }
    }
  }, [
    mobileExpand,
    submenuState,
    setSubmenuState,
    submenuIsHovered,
    submenuEl,
  ]);
  return (
    <>
      <div
        className="design__mobile-expand-wrapper"
        onClick={handleMobileMenu}
        ref={mobileExpand}
        role="button"
        tabIndex={0}
        onKeyPress={handleMobileMenu}>
        {submenuState !== "init" ? (
          <div className="design__mobile-close" />
        ) : (
          <HamburgerIcon />
        )}
      </div>
      <div className="design__logo-wrapper">
        <a href="/" className="design__logo">
          <BuffyLogo />
        </a>
      </div>
      <ul
        role="navigation"
        className="menu--demi design__nav design__nav--desktop">
        {menu.map((item) => (
          // Accordion = ({ title, children, className, isHtml, onOpen, onClose })
          <li key={item.slug}>
            {item.submenu ? (
              <Accordion
                title={item.title}
                showOnInit={
                  current === item.slug ||
                  item.submenu.find((sm) => current === sm.slug) !== undefined
                }
                titleRoute={`/${item.slug !== "index" ? item.slug : ""}`}
                className={`design__topnav ${
                  current === item.slug ? "design__currnav" : ""
                }`}>
                <ul className="design__subnav">
                  {item.submenu.map((sitem) => (
                    <li key={sitem.slug}>
                      <Link
                        to={`/${sitem.slug}`}
                        className={
                          current === sitem.slug ? "design__currnav" : ""
                        }>
                        {sitem.title}
                      </Link>
                    </li>
                  ))}
                </ul>
              </Accordion>
            ) : (
              <Link
                to={`/${item.slug !== "index" ? item.slug : ""}`}
                className={current === item.slug ? "design__currnav" : ""}>
                {item.title}
              </Link>
            )}
          </li>
        ))}
      </ul>

      <ul
        role="navigation"
        className={`menu--demi design__nav design__nav--mobile design__nav--${submenuState}`}
        ref={submenuRef}
        onAnimationStart={handleAnimationStart}
        onAnimationEnd={handleAnimationEnd}>
        {menu.map((item) => (
          <li key={item.slug}>
            {item.submenu ? (
              <Accordion
                title={item.title}
                showOnInit={
                  current === item.slug ||
                  item.submenu.find((sm) => current === sm.slug) !== undefined
                }
                titleRoute={`/${item.slug !== "index" ? item.slug : ""}`}
                className={`design__topnav ${
                  current === item.slug ? "design__currnav" : ""
                }`}>
                <ul className="design__subnav">
                  {item.submenu.map((sitem) => (
                    <li key={sitem.slug}>
                      <Link
                        to={`/${sitem.slug}`}
                        className={
                          current === sitem.slug ? "design__currnav" : ""
                        }
                        onClick={handleMobileMenu}
                        onKeyPress={handleMobileMenu}>
                        {sitem.title}
                      </Link>
                    </li>
                  ))}
                </ul>
              </Accordion>
            ) : (
              <Link
                to={`/${item.slug !== "index" ? item.slug : ""}`}
                className={current === item.slug ? "design__currnav" : ""}
                onClick={handleMobileMenu}
                onKeyPress={handleMobileMenu}>
                {item.title}
              </Link>
            )}

            {/* {item.submenu ? (
                <>
                  <CaretIcon />
                  <ul className="design__subnav">
                    {item.submenu.map(sitem => {
                      return (
                        <li key={sitem.slug}>
                          <Link
                            to={`/${sitem.slug}`}
                            className={
                              current === sitem.slug ? "design__currnav" : ""
                            }
                            onClick={handleMobileMenu}
                            onKeyPress={handleMobileMenu}>
                            {sitem.title}
                          </Link>
                        </li>
                      );
                    })}
                  </ul>
                </>
              ) : null} */}
          </li>
        ))}
      </ul>
    </>
  );
}

DesignHeader.propTypes = {
  menu: PropTypes.arrayOf(
    PropTypes.shape({
      slug: PropTypes.string.isRequired,
      submenu: PropTypes.arrayOf(
        PropTypes.shape({
          slug: PropTypes.string,
        }),
      ),
    }),
  ).isRequired,
  current: PropTypes.string,
};

DesignHeader.defaultProps = {
  current: "index",
};

export default DesignHeader;
