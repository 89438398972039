import React from "react";
// import { Link } from "gatsby";
// import { OutboundLink } from "gatsby-plugin-google-analytics";
import PropTypes from "prop-types";
import { getLink, getLinkProps } from "../../utils";

function Menu({
  menu,
  ulClass,
  liClass,
  liHeaderClass,
  showMenuTitle,
  menuTitleTag,
  menuTitleClass,
  ignoreHide,
  onClick,
  extras,
}) {
  const Tag = menuTitleTag;

  const menuHideClass = () => {
    if (ignoreHide) {
      return "";
    }
    if (!menu.hideOnDevice || !menu.hideOnDevice.length) {
      return "";
    }
    if (menu.hideOnDevice.includes("Desktop")) {
      return "shared__desktop-hide";
    }
    if (menu.hideOnDevice.includes("Mobile")) {
      return "shared__mobile-hide";
    }
    return "";
  };
  if (!menu) {
    return null;
  }

  let menuPreview = null;
  if (
    extras &&
    extras.preview &&
    typeof extras.preview !== "boolean" &&
    menu.id
  ) {
    menuPreview = (
      <button
        className="contentful-preview"
        type="button"
        onClick={() => {
          extras.preview.open(menu.id, extras.preview.options);
          extras.preview.close();
        }}>
        preview
      </button>
    );
  }
  const menuId =
    (extras && extras.skipToMenuTitle === menu.title && extras.skipToMenuId) ||
    null;
  return (
    <>
      {showMenuTitle ? (
        <Tag className={menuTitleClass}>{menu.title}</Tag>
      ) : null}

      <ul className={`${ulClass} ${menuHideClass()}`} id={menuId}>
        {menuPreview}
        {menu.items.map((item, index) => {
          const route = getLink({
            item,
            extras,
          });
          const routeProps = getLinkProps({ item });
          // const LinkTag = route.startsWith("http") ? OutboundLink : "a";
          const LinkTag = "a";
          return (
            <li
              className={
                liHeaderClass && index === 0
                  ? `${liHeaderClass} ${liClass}`
                  : liClass
              }
              key={item.id}>
              <LinkTag
                href={route}
                aria-label={
                  showMenuTitle ? `${menu.title} - ${item.title}` : item.title
                }
                {...routeProps}
                onClick={onClick}
                onKeyPress={onClick}>
                {item.title}
              </LinkTag>
            </li>
          );
        })}
      </ul>
    </>
  );
}

Menu.propTypes = {
  menu: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string,
    hideOnDevice: PropTypes.arrayOf(PropTypes.string),
    items: PropTypes.arrayOf(PropTypes.any),
  }).isRequired,
  ulClass: PropTypes.string,
  liClass: PropTypes.string,
  liHeaderClass: PropTypes.string,
  showMenuTitle: PropTypes.bool,
  menuTitleTag: PropTypes.string,
  menuTitleClass: PropTypes.string,
  ignoreHide: PropTypes.bool,
  onClick: PropTypes.func,
  extras: PropTypes.objectOf(PropTypes.any),
};

Menu.defaultProps = {
  ulClass: "",
  liClass: "",
  liHeaderClass: "",
  showMenuTitle: false,
  menuTitleTag: "h3",
  menuTitleClass: "",
  ignoreHide: false,
  onClick: () => {},
  extras: null,
};

export default Menu;
