import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { GatsbyImage, getImage, withArtDirection } from "gatsby-plugin-image";
import Img from "gatsby-image";

import CONSTANTS from "../../constants";

function Image({
  image,
  alt,
  className,
  style,
  loading,
  as,
  legacy,
  width,
  abState,
  id,
  // height,
}) {
  if (abState) {
    return (
      <div
        className={`ab-img ${
          className && className.includes("shared__bg-img") ? "ab-bg-img" : ""
        }`}
      />
    );
  }

  const servedImage = { ...image };
  if (servedImage.localImage) {
    servedImage.gatsbyImageData =
      servedImage.localImage.childImageSharp.gatsbyImageData;
  }
  if (servedImage.desktop && servedImage.desktop.localImage) {
    servedImage.desktop.gatsbyImageData =
      servedImage.desktop.localImage.childImageSharp.gatsbyImageData;
  }
  if (servedImage.mobile && servedImage.mobile.localImage) {
    servedImage.mobile.gatsbyImageData =
      servedImage.mobile.localImage.childImageSharp.gatsbyImageData;
  }

  const isGif = (img) => {
    const imgFile =
      img.file ||
      (img.desktop && img.desktop.file) ||
      (img.mobile && img.mobile.file);
    return (
      imgFile && imgFile.contentType && imgFile.contentType.includes("gif")
    );
  };
  const useLegacy = legacy || isGif(servedImage);

  const getImageData = (fimg, isMobile) => {
    if (!fimg || !fimg.gatsbyImageData) {
      return null;
    }
    let imageData = null;
    if (useLegacy) {
      const imageSrc =
        (fimg.gatsbyImageData.images.fallback &&
          fimg.gatsbyImageData.images.fallback.src) ||
        fimg.file.url;
      const imageSizes =
        (fimg.gatsbyImageData.images.fallback &&
          fimg.gatsbyImageData.images.fallback.sizes) ||
        fimg.gatsbyImageData.images.sources[0].sizes;

      // convert to gatsby-image as adapter
      const imageUrl = new URL(
        `${imageSrc.startsWith("https:") ? "" : "https:"}${imageSrc}`,
      );
      const baseUrl = `//${imageUrl.host}${imageUrl.pathname}`;
      let setSizes = [200, 400, 800, 1200, 1600, 2000];
      const setMult = [1, 1.5, 2, 3];
      if (isMobile) {
        setSizes = [160, 320, 640];
      } else if (width) {
        setSizes = [
          width * setMult[0],
          Math.round(width * setMult[1]),
          width * setMult[2],
          width * setMult[3],
        ];
      }
      const setSources = {
        default: [],
        webp: [],
      };

      setSizes.map((s, i) => {
        const part = `${baseUrl}?w=${s}&h=${Math.round(
          parseFloat(s / fimg.gatsbyImageData.width) *
            fimg.gatsbyImageData.height,
        )}&q=50`;
        const sizeRef = width ? `${setMult[i]}x` : `${s}w`;
        setSources.default.push(`${part} ${sizeRef}`);
        setSources.webp.push(`${part}&fm=webp ${sizeRef}`);
        return false;
      });
      let legacyImage = null;

      if (width) {
        legacyImage = {
          fixed: {
            aspectRatio: parseFloat(
              fimg.gatsbyImageData.width / fimg.gatsbyImageData.height,
            ),
            src: `${baseUrl}?w=${width}&q=50`,
            srcSet: setSources.default.join(`,\n`),
            srcWebp: `${baseUrl}?w=${width}&q=50&fm=webp`,
            srcSetWebp: setSources.webp.join(`,\n`),
            width,
            height: Math.round(
              parseFloat(width / fimg.gatsbyImageData.width) *
                fimg.gatsbyImageData.height,
            ),
          },
        };
        imageData = legacyImage.fixed;
      } else {
        legacyImage = {
          fluid: {
            aspectRatio: parseFloat(
              fimg.gatsbyImageData.width / fimg.gatsbyImageData.height,
            ),
            src: `${baseUrl}?w=800&q=50`,
            srcSet: setSources.default.join(`,\n`),
            srcWebp: `${baseUrl}?w=800&q=50&fm=webp`,
            srcSetWebp: setSources.webp.join(`,\n`),
            sizes: imageSizes,
          },
        };
        imageData = legacyImage.fluid;
      }
    } else {
      imageData = getImage(fimg.gatsbyImageData);
    }

    return imageData;
  };
  // Don't render image if no gatsbyImageData object
  if (
    !servedImage ||
    (servedImage && !servedImage.desktop && !getImageData(servedImage)) ||
    (servedImage &&
      servedImage.desktop &&
      !getImageData(servedImage.desktop)) ||
    (servedImage && servedImage.mobile && !getImageData(servedImage.mobile))
  ) {
    return null;
  }

  let imageSources = null;
  if (useLegacy) {
    imageSources =
      servedImage && servedImage.desktop && servedImage.mobile
        ? [
            getImageData(servedImage.mobile, true),
            {
              ...getImageData(servedImage.desktop),
              media: `(min-width: ${CONSTANTS.MOBILE_BREAKPOINT}px)`,
            },
          ]
        : null;

    if (width) {
      return (
        <Img
          fixed={
            (servedImage &&
              servedImage.desktop &&
              getImageData(servedImage.desktop)) ||
            getImageData(servedImage)
          }
          alt={alt}
          className={className}
          loading={loading}
          fadeIn={false}
          Tag={as}
        />
      );
    }
    return (
      <Img
        fluid={
          imageSources ||
          (servedImage &&
            servedImage.desktop &&
            getImageData(servedImage.desktop)) ||
          getImageData(servedImage)
        }
        alt={alt}
        className={className}
        style={style}
        loading={loading}
        fadeIn={false}
        Tag={as}
        importance={loading === "eager" ? "high" : "low"}
      />
    );
  }

  imageSources = useMemo(
    () =>
      servedImage && servedImage.desktop && servedImage.mobile
        ? withArtDirection(getImage(servedImage.desktop), [
            {
              media: `(max-width: ${CONSTANTS.MOBILE_BREAKPOINT}px)`,
              image: getImage(servedImage.mobile),
            },
          ])
        : null,
    [servedImage && servedImage.desktop, servedImage && servedImage.mobile],
  );

  return (
    <GatsbyImage
      image={
        imageSources ||
        (servedImage && servedImage.desktop && getImage(servedImage.desktop)) ||
        getImage(servedImage)
      }
      alt={alt}
      className={className}
      id={id}
      style={style}
      loading={loading}
      as={as}
      importance={loading === "eager" ? "high" : "low"}
    />
  );
}

Image.designSystemProps = {
  image: {
    type: "object",
    description: "Main image object.",
    propType: PropTypes.oneOfType([
      PropTypes.shape({
        gatsbyImageData: PropTypes.objectOf(PropTypes.any).isRequired,
        title: PropTypes.string,
      }),
      PropTypes.shape({
        desktop: PropTypes.shape({
          gatsbyImageData: PropTypes.objectOf(PropTypes.any).isRequired,
          title: PropTypes.string,
        }),
        mobile: PropTypes.shape({
          gatsbyImageData: PropTypes.objectOf(PropTypes.any).isRequired,
          title: PropTypes.string,
        }),
      }),
    ]),
    subProps: {
      desktop: {
        type: "object",
        description: "Desktop image object",
        required: false,
      },
      mobile: {
        type: "object",
        description: "Mobile image object",
        required: false,
      },
      gatsbyImageData: {
        type: "object",
        description: "Image object.",
        required: true,
      },
      title: {
        type: "string",
        description: "Image title.",
        required: false,
      },
    },
    default: null,
    required: false,
  },
  alt: {
    type: "string",
    description: "Alternative text for this image",
    default: "",
    propType: PropTypes.string,
    required: false,
  },
  className: {
    type: ["string", "array"],
    description: "List of classes for this image.",
    required: false,
    default: "",
    propType: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.arrayOf(PropTypes.string),
    ]),
  },
  style: {
    type: ["object", "array"],
    description: "Inline styles for image.",
    required: false,
    default: null,
    propType: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  },
  loading: {
    type: "string",
    description: "Loading strategy for image",
    default: null,
    propType: PropTypes.string,
    required: false,
  },
  as: {
    type: "string",
    description: "Name of HTML tag to wrap image in",
    default: "div",
    propType: PropTypes.string,
    required: false,
  },
  legacy: {
    type: "boolean",
    description: "Use legacy image plugin",
    default: CONSTANTS.USE_LEGACY_IMAGE_PLUGIN,
    propType: PropTypes.bool,
    required: false,
  },
  width: {
    type: "number",
    description: "Fixed image width",
    default: null,
    propType: PropTypes.number,
    required: false,
  },
  abState: {
    type: "boolean",
    description:
      "True if the page is in the process of determining AB test content.",
    default: null,
    propType: PropTypes.bool,
    required: false,
  },
  id: {
    type: "string",
    description: "Unique id of image",
    default: null,
    propType: PropTypes.string,
    required: false,
  },
  // height: {
  //   type: "number",
  //   description: "Fixed image height",
  //   default: null,
  //   propType: PropTypes.number,
  //   required: false,
  // },
};

const propTypes = {};
const defaultProps = {};
Object.entries(Image.designSystemProps).map(([k, v]) => {
  if (v.propType) {
    propTypes[k] = v.propType;
  }
  if (v.default || typeof v.default !== "undefined") {
    defaultProps[k] = v.default;
  }
  return false;
});
Image.propTypes = { ...propTypes };
Image.defaultProps = { ...defaultProps };

Image.displayName = "Image";

export default Image;
